<template>
    <div class="row kt-pt25">
        <div class="col-12 col-sm-12">
            <div class="kt-portlet kt-portlet--height-fluid" style="height: auto;">
                <div v-if="isLoading">
                    <Loader />
                </div>
                <div v-else class="kt-portlet__body svg_list_trainig">
                    <div style="float: left; width: 100%;">
                        <div style="float: left; width: calc(100% - 186px);">
                            <div class="menu" style="float: right;">
                                <sim-dropdown>
                                    <div class="btn">
                                        <i class="material-icons">more_horiz</i>
                                    </div>
                                    <template #menu>
                                        <sim-dropdown-item  v-for="(column, key) in columns.filter(el => el.title !== '')" :key="key" @click="column.enabled= !column.enabled" >
                                            <template #icon>
                                                <i class="material-icons" v-if="column.enabled">visibility</i>
                                                <i class="material-icons" v-else>visibility_off</i>
                                            </template>
                                            <template #label>
                                            {{ column.title }}
                                            </template>
                                        </sim-dropdown-item>
                                    </template>
                                </sim-dropdown>
                            </div>
                        </div>

                    </div>

                    <!-- aqui va la tabla -->
                    <sim-table :columns="columns.filter(el => el.enabled)" :source="pagination.data" @change="orderBy" bordered>
                        <template #td:seleted="{ record }">
                            <sim-checkbox v-model="record.item.selected"/>
                        </template>
                        <template #td:customer="{ record }">
                            <div class="w-100 img-product relative">
                                <div class="w-100 img-product relative" style="width: 40px; float: left;">
                                    <img :src="record.item.customer.profile_picture" class="img-responsive" style="border-radius: 100%;"/>
                                </div>
                                <div class="w-100 info-product" style="width: calc(100% - 40px); padding: 0 20px; float: left;"> 
                                    <div class="w-100 info-product"> <b> {{record.item.customer.email}} </b> </div>
                                    <div class="w-100 info-product"> <b> {{record.item.customer.first_name}} {{record.item.customer.last_name}} </b> </div>
                                </div>
                            </div>
                        </template>
                        <template #td:plan="{ record }">
                            <div class="w-100 img-product relative">
                                <div class="w-100 img-product relative" style="width: 40px; float: left;">
                                    <img :src="record.item.customer.plan.picture" class="img-responsive" style="border-radius: 100%;"/>
                                </div>
                                <div class="w-100 info-product" style="width: calc(100% - 40px); padding: 0 20px; float: left;"> 
                                    <div class="w-100 info-product"> <b> {{record.item.customer.plan.name}} </b> </div>
                                    <div class="w-100 info-product"> <b> {{record.item.customer.plan.service_type}} / ${{record.item.customer.plan.price / 100}}</b> </div>
                                </div>
                            </div>
                        </template>
                        <template #td:actions="{ record }">
                            <router-link :to="{ name: 'detailSubscriptions', params: { customer_id: record.item.customer.id } }" style="margin-right: 10px;" class="btn btn-label-warning text-uppercase">
                                <i class="material-icons">visibility</i>
                            </router-link> 
                        </template>
                    </sim-table>

                    <sim-laravel-pagination align="right" :data="pagination" @change="fetchPlans({ page: $event, ...query })" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
// ID
// Nombre
// Descripción
// Activa/Inactiva
import { 
    fetchSubscriptions
} from '@/api'
import moment from 'moment';
import Loader from '../../components/Loader';


export default {
    components: {
        Loader,
    },
    data: () => ({
        pagination: { data: [], current_page: 1 },
        loading: false,
        columns: [],
        limit: 10,
        query: {
            limit: 10,
            page:1,
            with: 'customer.plan'
        },
        search:'',
        isLoading: false,

    }),
    computed: {
        ...mapState({
        }),
        ...mapGetters({
            l: 'lang'
        })
    },
    methods: {
        ...mapMutations({
            setNavbarTitle: 'SET_NAVBAR_TITLE'
        }),
        ...mapActions({
            fetchPlans: 'plans/fetchPlans',
            deletePlans: 'plans/delete'
        }),
        searchUser(){
            this.isLoading = true;
            fetchSubscriptions({search: this.search, ...this.query}).then(res => this.pagination=  res.data).finally(() => {
                this.isLoading = false;
            });
        },
        orderBy(data){
            this.isLoading = true;
            if( this.search !== ''){
                fetchSubscriptions({search: this.search, sort_by: `${data.field},${data.order}`, ...this.query}).then(res => this.pagination=  res.data).finally(() => {
                    this.isLoading = false;
                });
            } else{
                fetchSubscriptions({ sort_by: `${data.field},${data.order}`, ...this.query}).then(res => this.pagination=  res.data).finally(() => {
                    this.isLoading = false;
                });
            }
        }

    },
    mounted() {
        this.isLoading = true;
        fetchSubscriptions(this.query).then(res => this.pagination=  res.data).finally(() => {
            this.isLoading = false;
        });
    },
    created() {
        this.setNavbarTitle([this.l.app.Subscription_List]);
        this.columns = [
            { title: this.l.app.Client, slot: 'customer', key: 'customer_id' , enabled: true, sorter:true },
            { title: this.l.app.Plan, slot: 'plan', key: 'stripe_plan', enabled: true, sorter:true },
            { title: this.l.app.Date, key: 'created_at_format', enabled: true },
            { title: this.l.app.Actions, slot: 'actions', alignment: 'right', enabled: true, style: {width: '320px'} },
        ]
    },
    watch:{
        search (val) {
            if (val.length > 3 ) {
                this.searchUser()
            }
        },
        pagination(val){
            val.data.forEach(value => {
                    value.created_at_format = moment(value.created_at).format('ll');
            });
        }
    }
}
</script>
