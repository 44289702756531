<template>
<div>
    <div class="flex flex-wrap mb-2">
        <label @click="$router.back()" class="btn text-uppercase">
            <i class="material-icons">keyboard_backspace</i>
            {{l.app.To_return}}
        </label>
    </div>
    <div class=" kt-pt25">
        <div class="w_100">
            <div class="kt-portlet" style="height: auto;">
                <div v-if="!loading" class="" style="padding: 32px;">
                    <div class="w_100 notification_title"  style="padding: 0px;">
                        <b style="float: left;padding: 5px 9px;">{{l.app.transaction_details}}</b>
                        <span style="background: #E7E7E7; color: #171717; display: table; padding: 5px 9px; border-radius: 16px; margin-right: 10px; font-size: 12px; float: right;" v-if="transaction.status === 2">{{l.app.SUBMITTED}}</span>
                        <span style="background: #E7E7E7; color: #171717; display: table; padding: 5px 9px; border-radius: 16px; margin-right: 10px; font-size: 12px; float: right;" v-if="transaction.status === 3">{{l.app.ON_HOLD}}</span>
                        <span style="background: #E7E7E7; color: #171717; display: table; padding: 5px 9px; border-radius: 16px; margin-right: 10px; font-size: 12px; float: right;" v-if="transaction.status === 4">{{l.app.APPROVED_ON_SUBMIT}}</span>
                        <span style="background: #E7E7E7; color: #171717; display: table; padding: 5px 9px; border-radius: 16px; margin-right: 10px; font-size: 12px; float: right;" v-if="transaction.status === 5">{{l.app.REJECTED_ON_SUBMIT}}</span>
                        <span style="background: #E7E7E7; color: #171717; display: table; padding: 5px 9px; border-radius: 16px; margin-right: 10px; font-size: 12px; float: right;" v-if="transaction.status === 6">{{l.app.SENT_FOR_REVIEW}}</span>
                        <span style="background: #E7E7E7; color: #171717; display: table; padding: 5px 9px; border-radius: 16px; margin-right: 10px; font-size: 12px; float: right;" v-if="transaction.status === 7">{{l.app.RECEIVED_IN_WAREHOUSE}}</span>
                        <span style="background: #E7E7E7; color: #171717; display: table; padding: 5px 9px; border-radius: 16px; margin-right: 10px; font-size: 12px; float: right;" v-if="transaction.status === 8">{{l.app.APPROVED_ON_REVIEW}}</span>
                        <span style="background: #E7E7E7; color: #171717; display: table; padding: 5px 9px; border-radius: 16px; margin-right: 10px; font-size: 12px; float: right;" v-if="transaction.status === 9">{{l.app.REJECTED_ON_REVIEW}}</span>
                    </div>
                    <div class="p_1" style="padding: 0px;">
                        <div class="w_100 transaction-header">
                            <b class="w_100 title-detail-transaction" style="font-size: 16px; margin-bottom: 20px;">
                                {{l.app.transaction}} #{{transaction.number}}
                            </b>
                            <span class="w_100">
                                <span style="background: #E7E7E7; color: #171717; display: table; padding: 5px 9px; border-radius: 16px; margin-right: 10px; font-size: 12px; float: left;" v-if="transaction.type === 1">Clothing - {{transaction.garments.length}} items</span>
                                <span style="background: #E7E7E7; color: #171717; display: table; padding: 5px 9px; border-radius: 16px; margin-right: 10px; font-size: 12px; float: left;" v-if="transaction.type === 2">Gowns - {{transaction.garments.length}} items</span>
                                <span style="background: #E7E7E7; color: #171717; display: table; padding: 5px 9px; border-radius: 16px; margin-right: 10px; font-size: 12px; float: left;" v-if="transaction.type === 3">Alta costura - {{transaction.garments.length}} items</span>
                                <span style="background: #E7E7E7; color: #171717; display: table; padding: 5px 9px; border-radius: 16px; margin-right: 10px; font-size: 12px; float: left;" v-if="transaction.type === 4">Designer bags - {{transaction.garments.length}} items</span>
                            </span>
                            <div class="w_100 cont_nun res">
                                {{l.app.estimated_customer_points}}: <b v-if="transaction.estimated_customer_points !== null">{{transaction.estimated_customer_points}}</b> <b v-else>0</b> BUTTONS
                            </div>
                            <div class="w_100 cont_nun res">
                                {{l.app.obtained_customer_points}}: <b v-if="transaction.obtained_customer_points !== null">{{transaction.obtained_customer_points}}</b> <b v-else>0</b> BUTTONS
                            </div>
                        </div>
                        <div class="w_100" style="padding-top: 40px; margin-bottom: 20px;">
                            <div class="w_100 img-product relative" style="width: 80px; float: left;">
                                <img :src="transaction.customer.profile_picture" class="img-responsive" style="border-radius: 100%;"/>
                            </div>
                            <div class="w_100 info-product" style="width: calc(100% - 80px); padding: 0 20px; float: left;"> 
                                <div class="w_100 info-product"> <b> {{transaction.customer.email}} </b> </div>
                                <div class="w_100 info-product"> <b> {{transaction.customer.first_name}} {{transaction.customer.last_name}} </b> </div>
                            </div>
                        </div>
                        <div class="w_100" style="padding: 20px; overflow-y: auto; max-height: 440px">
                            <div
                                class="title-detail-transaction mb-4"
                                style="font-size: 16px;"
                            >
                                {{l.app.Tracking}}
                            </div>
                            <div
                                class="list-traking"
                                v-for="(track, key) in transaction.status_tracking" :key="`status_tracking_${key}`">
                                <div class="icon" style="padding: 0">
                                    <span v-if="track.status === 2" class="material-icons">flip_camera_ios</span>
                                    <span v-if="track.status === 3" class="material-icons">collections_bookmark</span>
                                    <span v-if="track.status === 4" class="material-icons">photo</span>
                                    <span v-if="track.status === 5" class="material-icons">image_not_supported</span>
                                    <span v-if="track.status === 6" class="material-icons">local_shipping</span>
                                    <span v-if="track.status === 7" class="material-icons">local_shipping</span>
                                    <span v-if="track.status === 8" class="material-icons">done_all</span>
                                </div>
                                <div v-if="(key + 1) < transaction.status_tracking.length" class="line-traking"></div>
                                <div class="content_text">
                                    <samp v-if="track.status === 2" class="name_traking">{{l.app.SUBMITTED}}</samp>
                                    <samp v-if="track.status === 3" class="name_traking">{{l.app.ON_HOLD}}</samp>
                                    <samp v-if="track.status === 4" class="name_traking">{{l.app.APPROVED_ON_SUBMIT}}</samp>
                                    <samp v-if="track.status === 5" class="name_traking">{{l.app.REJECTED_ON_SUBMIT}}</samp>
                                    <samp v-if="track.status === 6" class="name_traking">{{l.app.SENT_FOR_REVIEW}}</samp>
                                    <samp v-if="track.status === 7" class="name_traking">{{l.app.RECEIVED_IN_WAREHOUSE}}</samp>
                                    <samp v-if="track.status === 8" class="name_traking">{{l.app.APPROVED_ON_REVIEW}}</samp>
                                    <samp v-if="track.status === 9" class="name_traking">{{l.app.REJECTED_ON_REVIEW}}</samp>
                                    <samp class="name_traking">{{track.timestamp}}</samp>
                                </div>
                            </div>
                            <div v-if="transaction.shipping_ref_id !== null" style="float: left; width: 50%; padding: 5px;">
                                <a style="line-height: 27px; background: rgb(0, 188, 212); color: rgb(255, 255, 255); display: table; padding: 5px 9px; border-radius: 16px; margin-right: 10px; font-size: 12px; float: right; width: 100%; text-align: center;" :href="transaction.shipping_ref_id" target="_blank" rel="noopener noreferrer">
                                    <span class="material-icons" style="line-height: 27px; position: absolute; margin-left: -32px; color: #fff;">local_shipping</span>
                                    XPS
                                </a>
                            </div>
                        </div>

                    </div>
                    
                    <div class="p_2">
                        <div class="w_100 title-detail-transaction" style="font-size: 16px; font-weight: bold; margin-bottom: 20px;">
                            {{l.app.Items}}
                        </div>
                        <template v-if="transaction.garments.length > 0">
                            <div class="w_100" v-for="(good, key) in transaction.garments" :key="`good_${key}`">
                                <div :class="['w_100', senDataOnHoldModal.rejected_garments.find(element => element === good.id) && 'selecet_garment_conten']" style="color: #171717">
                                    <div class="w_100 img-product relative" style="width: 50%; float: left; padding: 0px 10px 10px 0px;">
                                        <template v-if="transaction.status < 3 ">
                                            <div
                                                v-for="(url, key) in good.pictures"
                                                :key="key"
                                                class="engoj_find_img"
                                                style="width: 50%; padding: 10px;float: left;"
                                            >
                                                <img :src="url.path" class="img-responsive" :alt="good.name"/>
                                                <a :href="url.path" target="_blank" rel="noopener noreferrer"> {{l.app.Detail}} </a>
                                            </div>
                                        </template>
                                        <template v-else >
                                            <div class="engoj_find_img" style="width: 50%; padding: 10px;float: left;">
                                                <img :src="good.pictures[0].path" class="img-responsive" :alt="good.name"/>
                                                <a :href="good.pictures[0].path" target="_blank" rel="noopener noreferrer"> {{l.app.Detail}} </a>
                                            </div>
                                        </template>
                                    </div>
                                    <div class="w_100 info-product pt-2 row" style="width: 50%; padding: 16px 20px; float: left;"> 
                                        <h4 class="capital title-product" style="font-weight: bold;">
                                            {{ good.name }}
                                        </h4>
                                        <span class="col-12" style="font-weight: bold;">{{l.app.size}}: {{l.app.sizeArray['s_'+good.size]}}</span>
                                        <span class="col-12" style="font-weight: bold;">{{l.app.formGarment.f_12}}: {{good.fabric_material}}</span>
                                        <div class="col-12 content-price-rating" style="font-weight: bold;">
                                            <b>{{l.app.Price}}:</b> {{good.estimated_customer_points}} <span class="material-icons icon_poin" style="font-size: 14px;">lens</span>
                                        </div>

                                        <sim-button
                                            style="margin-top: 20px;"
                                            v-if="transaction.status === 2"
                                            @click="onHold(good.id)"
                                            class="error"
                                            outlined
                                        >
                                            <i class="material-icons">image_not_supported</i>
                                            {{ l.app.Reject_photo_quality }}
                                        </sim-button>

                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>

                    <div class="w_100 row" style="padding: 20px;">
                        <sim-dropdown-item v-if="transaction.status === 1" @click="deleteTransactions({ id: transaction.id, back: false })" class="col text-uppercase" >
                            <template #icon>
                                <i class="material-icons" style="color: #FF6565;">delete</i>
                            </template>
                            <template #label>
                                {{l.app.Remove}}
                            </template>
                        </sim-dropdown-item>

                        <sim-dropdown-item
                            v-if="transaction.status === 2"
                            @click="setConfirm({typeIcon:'info', text:'Se encuentra seguro de aprobar fotográficamente esta transacción?', typeFooter: 1 })" class="col text-uppercase"
                        >
                            <template #icon>
                                <i class="material-icons">photo</i>
                            </template>
                            <template #label>
                                {{l.app.Pass_photo_evaluation}}
                            </template>
                        </sim-dropdown-item>
                        <!-- rechaser toda la tranasaccion -->
                        <sim-dropdown-item
                            v-if="transaction.status === 2"
                            @click="setConfirm({typeIcon:'warning', text:'Se encuentra seguro de denegar fotográficamente esta transacción?', typeFooter: 2 })"
                            class="col text-uppercase"
                        >
                            <template #icon>
                                <i class="material-icons">image_not_supported</i>
                            </template>
                            <template #label>
                                {{ l.app.Reject_photo_evaluation }}
                            </template>
                        </sim-dropdown-item>
                        <!-- rechaser toda la tranasaccion -->
                        <sim-dropdown-item v-if="transaction.status === 6" @click="setConfirm({typeIcon:'info', text:'Se encuentra seguro de liberar los puntos para esta transaccion, sin que aprueve la resepcion de las prendas?', typeFooter: 0 })" class="col text-uppercase" >
                            <template #icon>
                                <i class="material-icons">lens</i>
                            </template>
                            <template #label>
                                {{l.app.Release_points}}
                            </template>
                        </sim-dropdown-item>
                        <sim-dropdown-item v-if="transaction.status === 6" @click="setConfirm({typeIcon:'warning', text:'Se encuentra seguro de que esta transaccion se encuentra fisicamente en su almacen?', typeFooter: 3 })" class="col text-uppercase" >
                            <template #icon>
                                <i class="material-icons">offline_pin</i>
                            </template>
                            <template #label>
                                {{l.app.Receive_transaction}}
                            </template>
                        </sim-dropdown-item>
                        <sim-dropdown-item v-if="transaction.status === 7" @click="setConfirm({typeIcon:'info', text:'Se encuentra seguro de aprobar fisicamente esta transacción?', typeFooter: 4 })" class="col text-uppercase" >
                            <template #icon>
                                <i class="material-icons">done_all</i>
                            </template>
                            <template #label>
                                {{l.app.Approve_physical_review}}
                            </template>
                        </sim-dropdown-item>
                        <sim-dropdown-item v-if="transaction.status === 7" @click="setConfirm({typeIcon:'warning', text:'Se encuentra seguro de denegar esta transacción?', typeFooter: 5 })" class="col text-uppercase" >
                            <template #icon>
                                <i class="material-icons" style="color: #FF6565;">highlight_off</i>
                            </template>
                            <template #label>
                                {{l.app.Refuse_physical_review}}
                            </template>
                        </sim-dropdown-item>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <sim-confirm
        :show="confirm"
        @close="confirm = false"
        :typeIcon="confirmData.typeIcon"
    >
        {{confirmData.text}}
        <template slot="footer">
            <sim-button v-if="confirmData.typeFooter === 0" class="mr-2" @click="transactionsReleasePoints()" :loading="loading">
                <i class="material-icons">save</i>
                {{l.app.Save_Changes}}
            </sim-button>
            <sim-button v-else-if="confirmData.typeFooter === 1" class="mr-2" @click="transactionsSetApprovedOnSubmitModal()" :loading="loading">
                <i class="material-icons">save</i>
                {{l.app.Save_Changes}}
            </sim-button>
            <sim-button v-else-if="confirmData.typeFooter === 2" class="mr-2" @click="transactionsSetRejectedOnSubmitModal()" :loading="loading">
                <i class="material-icons">save</i>
                {{l.app.Save_Changes}}
            </sim-button>
            <sim-button v-else-if="confirmData.typeFooter === 3" class="mr-2" @click="transactionsSetReceivedInWarehouse()" :loading="loading">
                <i class="material-icons">save</i>
                {{l.app.Save_Changes}}
            </sim-button>
            <sim-button v-else-if="confirmData.typeFooter === 4" class="mr-2" @click="transactionsSetApprovedOnReviewModal()" :loading="loading">
                <i class="material-icons">save</i>
                {{l.app.Save_Changes}}
            </sim-button>
            <sim-button v-else-if="confirmData.typeFooter === 5" class="mr-2" @click="transactionsSetRejectedOnReview()" :loading="loading">
                <i class="material-icons">save</i>
                {{l.app.Save_Changes}}
            </sim-button>
            <sim-button outlined @click="confirm = false">
                <i class="material-icons">clear</i>
                {{l.app.Cancel}}
            </sim-button>
        </template>
    </sim-confirm>
    <sim-Modal
        :show="modal"
        @close="modal = false"
    >
        <h3 v-if="transaction !== null" slot="header">
            <b class="col-12 title-detail-transaction" style="font-size: 16px; margin-bottom: 20px;">
                {{l.app.transaction}} #{{transaction.number}}
            </b>
        </h3>
        <div class="row">
            <div
                class="col-12 form-group"
                v-for="(input, key_input) in form.filter(el => el.visibility === true)"
                :key="key_input"
            >
                <label :for="input.atribute">{{input.label}}</label>

                <sim-input
                    v-if="input.type !== 'select' && input.type !== 'garments' && input.type !== 'attachment' && input.type !== 'garmentsPoints'"
                    :id="input.atribute"
                    :type="input.type"
                    :error="error(input.atribute, { attribute: input.atribute })"
                    v-model="senData[input.atribute]"
                />

                <sim-select
                    v-else-if="input.type === 'select'"
                    :source="source[input.source]"
                    :error="error(input.atribute, { attribute: 'Estado' })"
                    :value="senData[input.atribute]"
                    v-model="senData[input.atribute]"
                    @input="activeInput"
                />

                <div v-else-if="input.type === 'garments'" class="col-12 row">
                    <div class="col-12 col-sm-6" v-for="(good, key) in transaction.garments" :key="`good_modal_${key}`">
                        <div class="w_100" style="color: #171717">
                            <div class="w_100" style="">
                                <div class="w_100 img-product relative" style="width: 80px; float: left;">
                                    <div class="engoj_find_img">
                                        <img :src="good.pictures[0].path" class="img-responsive" :alt="good.name"/>
                                    </div>
                                </div>
                                <div class="w_100 info-product" style="width: calc(100% - 80px); padding: 0 20px; float: left;"> 
                                    <h4 class="capital title-product" style="font-weight: bold;">
                                        {{ good.name }}
                                    </h4>
                                    <span class="col-12" style="font-weight: bold;">{{l.app.size}} {{good.size}}</span>
                                    <span class="col-12" v-if="good.type === 1">{{l.app.CLOTHING}}</span>
                                    <span class="col-12" v-if="good.type === 2">{{l.app.GOWN}}</span>
                                    <span class="col-12" v-if="good.type === 3">{{l.app.HAUTE_COUTURE}}</span>
                                    <span class="col-12" v-if="good.type === 4">{{l.app.DESIGNER_BAG}}</span>
                                    <div class="w_100 content-price-rating" style="font-weight: bold;">
                                        {{good.points_value}} BOTONES
                                    </div>
                                    <sim-button outlined @click=" senData[input.atribute].push(good.id) ">
                                        <i class="material-icons">clear</i>
                                        {{l.app.Cancel}}
                                    </sim-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else-if="input.type === 'garmentsPoints'" class="col-12 row">
                    <div class="col-12 col-sm-6" v-for="(good, key) in transaction.garments" :key="`good_modal_${key}`">
                        <div class="w_100" style="color: #171717">
                            <div class="w_100" style="">
                                <div class="w_100 info-product mt-2" style="width: 100%; padding: 0px; float: left;">
                                    <label>{{l.app.points}}: </label>
                                    <sim-input
                                        :id="`poid-${good.id}`"
                                        type="number"
                                        v-model="senDataP[key].points"
                                    />
                                </div>
                                <div class="w_100 img-product relative" style="width: 80px; float: left;">
                                    <div class="engoj_find_img" style="height: 160px;">
                                        <img :src="good.pictures[0].path" class="img-responsive" :alt="good.name"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else-if="input.type === 'attachment'">
                    <sim-img-cropper
                        :aspectRatio="18/25"
                        type="product"
                        :img_url="picture.pictureV"
                        @editImg="($event) =>{ senData[input.atribute] = $event }"
                        @editImgVie="addImgPV($event)"
                    />
                </div>
            </div>
        </div>

        <template slot="footer">
            <sim-button v-if="typeBtn === 1" class="mr-2" @click="transactionsSetOnHold()" :loading="loading">
                <i class="material-icons">save</i>
                {{l.app.Save_Changes}}
            </sim-button>
            <sim-button v-if="typeBtn === 2" class="mr-2" @click="transactionsSetRejectedOnSubmit()" :loading="loading">
                <i class="material-icons">save</i>
                {{l.app.Save_Changes}}
            </sim-button>
            <sim-button v-if="typeBtn === 3" class="mr-2" @click="transactionsSetApprovedOnReview()" :loading="loading">
                <i class="material-icons">save</i>
                {{l.app.Save_Changes}}
            </sim-button>
            <sim-button outlined @click="modal = false">
                <i class="material-icons">clear</i>
                {{l.app.Cancel}}
            </sim-button>
        </template>

    </sim-Modal>
</div>

</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import {
    transactionsFromRejected,
    transactionsReleasePoints,
    transactionsSetOnHold,
    transactionsSetApprovedOnSubmit,
    transactionsSetRejectedOnSubmit,
    transactionsSetApprovedOnReview,
    transactionsSetReceivedInWarehouse,
    transactionsSetRejectedOnReview
} from '@/api'

export default {
    data: () => ({
        search:'',
        confirm:false,
        confirmData:{
            typeIcon:'warning',
            text:'aaaaa',
            typeFooter: 0
        },
        modal:false,
        form:[],
        senDataOnHoldModal: {
            reason: '',
            description: '',
            rejected_garments:[]
        },
        senData: {},
        senDataP:[],
        picture: {label: 'Primera Imagen', pictureV: '', file: null},
        transactionData: null,
        typeBtn: 1
    }),
    computed: {
        ...mapGetters({
            hasError: 'hasError',
            error: 'error',
            l: 'lang'
        }),
        ...mapState({
            transaction: state => state.transactions.transaction,
            loading: state => state.transactions.loading,
        })
    },
    methods: {
        ...mapMutations({
            setNavbarTitle: 'SET_NAVBAR_TITLE'
        }),
        ...mapActions({
            fetchTransaction: 'transactions/fetchTransaction',
            deleteTransactions: 'transactions/delete'
        }),

        addImgPV(img) {
            this.picture.pictureV = img;
        },
        transactionsFromRejected(){
            transactionsFromRejected().then(() => {

                this.fetchTransaction(this.transaction.id);
            })
        },
        setConfirm(data){
            this.confirmData = data;
            this.confirm = true;
        },
        transactionsReleasePoints(){
            transactionsReleasePoints(this.transaction.id)
            .then(() => {
                this.confirm = false;
                this.fetchTransaction(this.transaction.id);
            })
            .catch(() => {
                this.confirm = false;
            });
        },
        transactionsSetOnHoldModal(){
            //aquie se tiene que hacer un modal para pedir esta data
            this.form = [
                {label: this.l.app.Reason, atribute: 'reason', type: 'text', visibility: true},
                {label: this.l.app.Description, atribute: 'description', type: 'text', visibility: true}
            ];

            this.senData = this.senDataOnHoldModal;
            this.modal= true;
            this.picture= {label: 'Primera Imagen', pictureV: '', file: null};
            this.typeBtn=1;
        },
        transactionsSetOnHold(){
            transactionsSetOnHold(this.transaction.id, this.senData).then(() => {
                this.fetchTransaction(this.transaction.id);
                this.modal = false;
            })
        },
        transactionsSetApprovedOnSubmitModal() {
            transactionsSetApprovedOnSubmit(this.transaction.id)
                .then(() => {
                    this.confirm = false;
                    this.$message.success({
                        message: this.l.messages.responses.Transaction_status,
                        position: `top-right`
                    })
                    this.fetchTransaction(this.transaction.id);
                })
        },
        transactionsSetRejectedOnSubmitModal() {

            if(this.senDataOnHoldModal.rejected_garments.length > 0){
                this.transactionsSetOnHoldModal();
            } else{
                // Libera las prendas y las coloca en su estado inicial
                // Se puede crear una nueva transaccion desde una rechazada en este estado
                this.form = [
                    {label: this.l.app.Reason, atribute: 'reason', type: 'text', visibility: true},
                    {label: this.l.app.Description, atribute: 'description', type: 'text', visibility: true},
                    // {label: this.l.app.Images, atribute: 'attachment', type: 'attachment', visibility: true},
                ];

                this.senData = {
                    reason: '',
                    description: '',
                    rejected_garments:[]
                };
                this.modal= true;
                this.picture= {label: 'Primera Imagen', pictureV: '', file: null};
                this.typeBtn=2;
            }
        },
        transactionsSetRejectedOnSubmit(){
            if(this.senData.rejected_garments.length > 0){
                const transactionsSetRejectedOnSubmitData = new FormData();
                Object.entries(this.senData).forEach((entry)=>{
                    transactionsSetRejectedOnSubmitData.append(entry[0], entry[1]);
                })
                transactionsSetRejectedOnSubmit(this.transaction.id, transactionsSetRejectedOnSubmitData).then(() => {
                    this.$message.success({
                        message: this.l.messages.responses.Transaction_status,
                        position: `top-right`
                    })
                    this.fetchTransaction(this.transaction.id);
                    this.modal = false;
                })
            } else {
                transactionsSetRejectedOnSubmit(this.transaction.id, this.senData).then(() => {
                    this.$message.success({
                        message: this.l.messages.responses.Transaction_status,
                        position: `top-right`
                    })
                    this.fetchTransaction(this.transaction.id);
                    this.modal = false;
                })
            }
        },
        transactionsSetApprovedOnReviewModal() {
            this.form = [
                {
                    label: this.l.app.garments,
                    atribute: 'rejected_garments',
                    type: 'garmentsPoints',
                    visibility: true
                },
            ];

            this.modal = true;
            // this.picture= {label: 'Primera Imagen', pictureV: '', file: null};
            this.typeBtn = 3;
        },
        transactionsSetApprovedOnReview() {
            transactionsSetApprovedOnReview(this.transaction.id, this.senDataP)
                .then(() => {
                    this.$message.success({
                        message: this.l.messages.responses.Transaction_status,
                        position: `top-right`
                    })
                    this.confirm = false;
                    this.modal = false;
                    this.fetchTransaction(this.transaction.id);
                })
        },
        transactionsSetReceivedInWarehouse(){
            transactionsSetReceivedInWarehouse(this.transaction.id)
            .then(() => {
                this.confirm = false;
                this.modal = false;
                this.$message.success({
                    message: this.l.messages.responses.Transaction_status,
                    position: `top-right`
                })
                this.fetchTransaction(this.transaction.id);
            })
        },
        transactionsSetRejectedOnReview(){
            transactionsSetRejectedOnReview(this.transaction.id)
            .then(() => {
                this.confirm = false;
                this.modal = false;
                this.$message.success({
                    message: this.l.messages.responses.Transaction_status,
                    position: `top-right`
                })
                this.fetchTransaction(this.transaction.id);
            })
        },
        onHold(id) {
            const index = this.senDataOnHoldModal.rejected_garments.indexOf(id);
            if (index > -1) {
                this.senDataOnHoldModal.rejected_garments.splice(index, 1);
            } else {
                this.senDataOnHoldModal.rejected_garments.push(id);
            }
        }
    },
    created() {
        this.setNavbarTitle([this.l.app.List_of_Transactions, this.l.app.detail])
        const id = this.$route.params.transactions_id;


        this.fetchTransaction(id).then( () => {
             this.transaction.garments.forEach(element => {
                this.senDataP.push({
                    garment_id: element.id,
                    points: element.estimated_customer_points
                });
            });
        });
    },
    watch:{
        search (val) {
            if (val.length > 3 ) {
                this.searchUser()
            }
        },
        modal( val ){
          if(val){
              this.confirm= false
          }
        }
    }
}
</script>

<style lang="scss">
.w_100{
    width: 100%;
    float: left;
}
.p_2{
    width: 66%;
    padding: 0 20px;
    float: left;
}
.p_1{
    width: 33.33333%;
    padding: 0 20px;
    float: left;
}
.engoj_find_img {
    height: 280px;
    position: relative;
    img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 90%;
    }
    a {
        background: #fff;
        position: absolute;
        bottom: 14px;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
    }
}
.list-traking{
    width: 100%;
    float: left;
    min-height: 100px;
    position: relative;

    .icon{
        float: left;
        width: 36px;
        height: 36px;
        border: 2px solid #CCCCCC;
        border-radius: 100%;
        margin-right: 11px;
        .material-icons{
            float: left;
            width: 100%;
            text-align: center;
            font-size: 23px;
            line-height: 31px;
        }
    }

    .line-traking{
        height: calc(100% - 36px);
        position: absolute;
        border: 2px solid #ccc;
        left: 16px;
        top: 36px;
    }

    .content_text{
        width: calc(100% - 48px);
        float: left;

        .name_traking{
            width: calc(100% - 48px);
            float: left;
            font-weight: bold;
            font-size: 18px;
        }

        .date{
            width: calc(100% - 48px);
            float: left;
        }
    }

    &.active {
        .icon {
            border: 2px solid #626AC9;
            svg {
                fill: #626AC9;
            }
        }
        .line-traking{
            border: 2px solid #626AC9;
        }
    }
}

button.error {
    outline-color: #b11623 !important;
    border-color: #b11623 !important;
    color: #b11623 !important;
    i {
        color: #b11623 !important;
    }
}

.selecet_garment_conten {
    background:#ffeaec;
    button.error {
        opacity: .5;
    }
}
</style>