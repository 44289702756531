<template>
    <div class="row kt-pt25">
        <div class="col-12 col-sm-12">

            <div class="kt-portlet kt-portlet--height-fluid" style="height: auto;">
                
                <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item" @click="tabs = 2">
                        <div :class="['nav-link', tabs === 2 && 'active']">
                            <span class="m--visible-desktop-inline-block">
                                {{l.app.GOWN}}
                            </span>
                        </div>
                    </li>
                    <li class="nav-item" @click="tabs = 3">
                        <div :class="['nav-link', tabs === 3 && 'active']">
                            <span class="m--visible-desktop-inline-block">
                                {{l.app.HAUTE_COUTURE}}
                            </span>
                        </div>
                    </li>
                </ul>
                <div v-if="isLoading">
                    <Loader />
                </div>
                <div v-else class="kt-portlet__body svg_list_trainig">
                    <div style="float: left; width: 100%;">
                        <div style="float: left; width: 100%;">
                            <!-- <div class="kt-input-icon kt-input-icon--left col-sm-5">
                                <input type="text" v-model="search" class="form-control" placeholder="Search...">
                                <span @click="searchUser" class="kt-input-icon__icon kt-input-icon__icon--left" style="cursor: pointer;">
                                    <span> <i class="material-icons">search</i> </span>
                                </span>
                            </div> -->
                            <div class="menu" style="float: left;right: 0;top: 0;">
                                <sim-dropdown>
                                    <div class="btn">
                                        <i class="material-icons">more_horiz</i>
                                    </div>
                                    <template #menu>
                                        <sim-dropdown-item  v-for="(column, key) in columns.filter(el => el.title !== '')" :key="key" @click="column.enabled= !column.enabled" >
                                            <template #icon>
                                                <i class="material-icons" v-if="column.enabled">visibility</i>
                                                <i class="material-icons" v-else>visibility_off</i>
                                            </template>
                                            <template #label>
                                            {{ column.title }}
                                            </template>
                                        </sim-dropdown-item>
                                    </template>
                                </sim-dropdown>
                                <div style="float: left; width: 100%; padding-top: 10px;">
                                    <span  :class="['btn-filter', status === 1 && 'active']" @click="status = 1">{{l.app.PENDING}}</span>
                                    <span  :class="['btn-filter', status === 2 && 'active']" @click="status = 2">{{l.app.BEING_PREPARED}}</span>
                                    <span  :class="['btn-filter', status === 3 && 'active']" @click="status = 3">{{l.app.SENT}}</span>
                                    <span  :class="['btn-filter', status === 4 && 'active']" @click="status = 4">{{l.app.CANCELED}}</span>
                                    <span  :class="['btn-filter', status === 5 && 'active']" @click="status = 5">{{l.app.COMPLETED}}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- aqui va la tabla -->
                    <sim-table :columns="columns.filter(el => el.enabled)" :source="pagination.data" @change="orderBy" bordered>
                        <template #td:seleted="{ record }">
                            <sim-checkbox v-model="record.item.selected"/>
                        </template>
                        <template #td:img="{ record }">
                            <span v-if="record.item.garments.length > 0" style="width: 40px; height: 40px; border-radius: 50%; background: #ddd; display: block; margin-left: 10px; overflow: hidden;">
                                <img :src="record.item.garments[0].picture_urls[0].replace(/\.([^\.]*)$/, '_80x112.' + '$1')" alt="" style="width: 100%; height: 100%;">
                            </span>
                            <span v-else class="material-icons" style="width: 40px; height: 40px; border-radius: 50%; background: #ddd; display: block; margin-left: 10px; overflow: hidden; text-align: center; line-height: 40px;">
                                image_not_supported
                            </span>
                        </template>
                        <template #td:number="{ record }">
                            <div class="w-100 info-product"> <b> {{record.item.number}} </b> </div>
                        </template>
                        <template #td:customer="{ record }">
                            <div class="w-100 img-product relative">
                                <div class="w-100 info-product" style="width: calc(100% - 40px); padding: 0 20px; float: left;"> 
                                    <div class="w-100 info-product"><b>
                                        {{getKey(record, ['item','customer','email'])}}
                                    </b></div>
                                    <div class="w-100 info-product"><b>
                                        {{getKey(record, ['item','customer','first_name'])}}
                                        {{getKey(record, ['item','customer','last_name'])}}
                                    </b></div>
                                </div>
                            </div>
                        </template>
                        <template #td:type="{ record }">
                            <span v-if="record.item.type === 1">{{l.app.CLOTHING}}</span>
                            <span v-if="record.item.type === 2">{{l.app.GOWN}}</span>
                            <span v-if="record.item.type === 3">{{l.app.HAUTE_COUTURE}}</span>
                            <span v-if="record.item.type === 4">{{l.app.DESIGNER_BAG}}</span>
                        </template>
                        <template #td:status="{ record }">
                            <span v-if="record.item.status === 1">{{l.app.PENDING}}</span>
                            <span v-if="record.item.status === 2">{{l.app.BEING_PREPARED}}</span>
                            <span v-if="record.item.status === 3">{{l.app.SENT}}</span>
                            <span v-if="record.item.status === 4">{{l.app.CANCELED}}</span>
                            <span v-if="record.item.status === 5">{{l.app.COMPLETED}}</span>
                        </template>
                        <template #td:actions="{ record }">
                            <sim-dropdown right fixed>
                                <span class="material-icons">more_vert</span>
                                <template #header>
                                    {{l.app.Actions}}
                                </template>
                                <template #menu>
                                    <sim-dropdown-item :to="{ name: 'orders_make', params: { order_id: record.item.id } }" class="text-uppercase">
                                        <template #icon>
                                            <i class="material-icons" style="color: #FE9801;">visibility</i>
                                        </template>
                                        <template #label>
                                            {{l.app.Detail}}
                                        </template>
                                    </sim-dropdown-item>

                                    <sim-dropdown-item v-if="record.item.status === 1" @click="handler_setbeingprepared(record.item.id,)" class="text-uppercase" >
                                        <template #icon>
                                            <i class="material-icons" style="color: #626AC9;">assignment_turned_in</i>
                                        </template>
                                        <template #label>
                                            {{l.app.Prepare}}
                                        </template>
                                    </sim-dropdown-item>
                                    <sim-dropdown-item v-if="record.item.status === 2" @click="handler_setsent(record.item.id)" class="text-uppercase" >
                                        <template #icon>
                                            <i class="material-icons" style="color: #00BCD4;">send</i>
                                        </template>
                                        <template #label>
                                            {{l.app.Send}}
                                        </template>
                                    </sim-dropdown-item>
                                    <sim-dropdown-item v-if="record.item.status !== 5" @click="handler_setcanceled(record.item.id,)" class="text-uppercase" >
                                        <template #icon>
                                            <i class="material-icons" style="color: #FF6565;">cancel_schedule_send</i>
                                        </template>
                                        <template #label>
                                            {{l.app.Cancel}}
                                        </template>
                                    </sim-dropdown-item>
                                    <sim-dropdown-item  v-if="record.item.status === 3" @click="handler_setcompleted(record.item.id,)" class="text-uppercase" >
                                        <template #icon>
                                            <i class="material-icons" style="color: rgb(29 239 37);">check_circle_outline</i>
                                        </template>
                                        <template #label>
                                            {{l.app.Finish}}
                                        </template>
                                    </sim-dropdown-item>
                                </template>
                            </sim-dropdown>
                        </template>
                    </sim-table>

                    <sim-laravel-pagination align="right" :data="pagination" @change="fetchOrders({ with:'pictures', page: $event, ...query })" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import { setbeingprepared, setsent, setcanceled, setcompleted,} from '@/api';
import moment from 'moment';
import Loader from '../../components/Loader';

export default {
    data: () => ({
        columns: [],
        status: 1,
        limit: 10,
        query: {
            limit: 10,
            with: 'customer,garments.pictures',
            status: 1,
            type: 2
        },
        search:'',
        tabs: 2,
        isLoading: false,

    }),
    components: {
        Loader,
    },
    computed: {
        ...mapState({
            pagination: state => state.order.pagination,
            loading: state => state.order.loading,
        }),
        ...mapGetters({
            l: 'lang'
        })
    },
    methods: {
        ...mapMutations({
            setNavbarTitle: 'SET_NAVBAR_TITLE'
        }),
        ...mapActions({
            fetchOrders: 'order/fetchOrders',
            deleteorders: 'order/delete'
        }),
        getKey(obj, klist) {
            while (obj && klist.length) {
                obj = obj[klist.shift()];
            }
            return obj;
        },
        searchUser(){
            this.isLoading = true;
            this.fetchOrders({...this.query, search: this.search, page:1}).finally(() => {
                this.isLoading = false;
            });
        },
        orderBy(data){
            this.isLoading = true;
            if( this.search !== ''){
                this.fetchOrders({...this.query, search: this.search, sort_by: `${data.field},${data.order}`, page:1}).finally(() => {
                    this.isLoading = false;
                });
            } else{
                this.fetchOrders({...this.query, sort_by: `${data.field},${data.order}`, page:1}).finally(() => {
                    this.isLoading = false;
                });
            }
        },
        handler_setbeingprepared(id){
            this.isLoading = true;
            if (confirm(this.l.app.confirm_order_1)) {
                setbeingprepared(id).then(()=>{
                    this.$message.success({
                        message: this.l.messages.responses.Order_status,
                        position: `top-right`
                    })
                    this.fetchOrders({ page:1, ...this.query })
                }).finally(() => {
                    this.isLoading = false;
                });
            }
        },
        handler_setsent(id){
            this.isLoading = true;
            if (confirm(this.l.app.confirm_order_2)) {
                setsent(id).then(()=>{
                    this.$message.success({
                        message: this.l.messages.responses.Order_status,
                        position: `top-right`
                    })
                    this.fetchOrders({ page:1, ...this.query })
                }).finally(() => {
                    this.isLoading = false;
                });
            }
        },
        handler_setcanceled(id){
            this.isLoading = true;
            if (confirm(this.l.app.confirm_order_3)) {
                setcanceled(id).then(()=>{
                    this.$message.success({
                        message: this.l.messages.responses.Order_status,
                        position: `top-right`
                    })
                    this.fetchOrders({ page:1, ...this.query })
                }).finally(() => {
                    this.isLoading = false;
                });
            }
        },
        handler_setcompleted(id){
            this.isLoading = true;
            if (confirm(this.l.app.confirm_order_4)) {
                setcompleted(id).then(()=>{
                    this.$message.success({
                        message: this.l.messages.responses.Order_status,
                        position: `top-right`
                    })
                    this.fetchOrders({ page:1, ...this.query })
                }).finally(() => {
                    this.isLoading = false;
                });
            }
        }
    },
    mounted() {
        this.isLoading = true;
        this.fetchOrders({...this.query, page:1}).finally(() => {
            this.isLoading = false;
        });

        if(this.$route.params.status != undefined) {
            setTimeout(() => {
                this.status = this.$route.params.status;
            }, 500);
        }
    },
    created() {
        this.setNavbarTitle([this.l.app.List_of_orders, this.l.app.detail])
        this.columns = [
            { title: '', slot: 'seleted', alignment: 'right', enabled: false, style: {width: '29px'} },
            { title: '', slot: 'img', alignment: 'right', enabled: true, style: {width: '40px'} },
            { title: this.l.app.columnsOrder.c_o_1, slot: 'number', key: 'number', alignment: 'right', enabled: true, sorter:true },
            { title: this.l.app.columnsOrder.c_o_2, slot: 'customer', enabled: true },
            { title: this.l.app.columnsOrder.c_o_3, key: 'type', slot: 'type',  enabled: true },
            { title: this.l.app.columnsOrder.c_o_4, key: 'status', slot: 'status', alignment: 'right', enabled: true, sorter:true },
            { title: this.l.app.columnsOrder.c_o_5, key: 'points_deferred', alignment: 'right', enabled: true },
            { title: this.l.app.columnsOrder.c_o_6, key: 'created_at_format', enabled: true },
            { title: this.l.app.columnsOrder.c_o_7, slot: 'actions', alignment: 'right', enabled: true, style: {width: '242px'} },
        ]
    },
    watch:{
        search (val) {
            if (val.length > 3 ) {
                this.searchUser()
            }
        },
        pagination(val){
            val.data.forEach(value => {
                    value.created_at_format = moment(value.created_at).format('ll');
            });
        },
        tabs(val){
            this.isLoading = true;
            this.query.type= val;
            this.fetchOrders({...this.query, page:1}).finally(() => {
                this.isLoading = false;
            });
        },
        status(val){
            this.isLoading = true;
            this.query.status= val;
            this.fetchOrders(this.query).finally(() => {
                 this.isLoading = false;
            });
        }
    }
}
</script>
