var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row kt-pt25"},[_c('div',{staticClass:"col-12 col-sm-12"},[_c('div',{staticClass:"kt-portlet kt-portlet--height-fluid",staticStyle:{"height":"auto"}},[(_vm.isLoading)?_c('div',[_c('Loader')],1):_c('div',{staticClass:"kt-portlet__body svg_list_trainig"},[_c('div',{staticStyle:{"float":"left","width":"100%"}},[_c('div',{staticStyle:{"float":"left","width":"calc(100% - 186px)"}},[_c('div',{staticClass:"menu",staticStyle:{"float":"right"}},[_c('sim-dropdown',{scopedSlots:_vm._u([{key:"menu",fn:function(){return _vm._l((_vm.columns.filter(function (el) { return el.title !== ''; })),function(column,key){return _c('sim-dropdown-item',{key:key,on:{"click":function($event){column.enabled= !column.enabled}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(column.enabled)?_c('i',{staticClass:"material-icons"},[_vm._v("visibility")]):_c('i',{staticClass:"material-icons"},[_vm._v("visibility_off")])]},proxy:true},{key:"label",fn:function(){return [_vm._v(" "+_vm._s(column.title)+" ")]},proxy:true}],null,true)})})},proxy:true}])},[_c('div',{staticClass:"btn"},[_c('i',{staticClass:"material-icons"},[_vm._v("more_horiz")])])])],1)])]),_c('sim-table',{attrs:{"columns":_vm.columns.filter(function (el) { return el.enabled; }),"source":_vm.pagination.data,"bordered":""},on:{"change":_vm.orderBy},scopedSlots:_vm._u([{key:"td:seleted",fn:function(ref){
var record = ref.record;
return [_c('sim-checkbox',{model:{value:(record.item.selected),callback:function ($$v) {_vm.$set(record.item, "selected", $$v)},expression:"record.item.selected"}})]}},{key:"td:img",fn:function(ref){
var record = ref.record;
return [_c('span',{staticStyle:{"width":"40px","height":"40px","border-radius":"50%","background":"#ddd","display":"block","margin-left":"10px","overflow":"hidden"}},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":record.item.picture,"alt":""}})])]}},{key:"td:active",fn:function(ref){
var record = ref.record;
return [(record.item.enabled)?_c('span',{staticClass:"material-icons icon_table"},[_vm._v("done")]):_c('span',{staticClass:"material-icons icon_table"},[_vm._v("clear")])]}},{key:"td:actions",fn:function(ref){
var record = ref.record;
return [_c('router-link',{staticClass:"btn btn-label-warning text-uppercase",staticStyle:{"margin-right":"10px"},attrs:{"to":{ name: 'edit_plans', params: { plans_id: record.item.id } }}},[_c('i',{staticClass:"material-icons"},[_vm._v("visibility")])])]}}])}),_c('sim-laravel-pagination',{attrs:{"align":"right","data":_vm.pagination},on:{"change":function($event){return _vm.fetchPlans(Object.assign({}, {page: $event}, _vm.query))}}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }