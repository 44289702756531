<template>
    <div class="row kt-pt25">
        <div class="col-12 col-sm-12">
            <div class="kt-portlet kt-portlet--height-fluid" style="height: auto;">
                <div v-if="isLoading">
                    <Loader />
                </div>
                <div v-else class="kt-portlet__body svg_list_trainig">
                    <div style="float: left; width: 100%;">
                        <div style="float: left; width: calc(100% - 192px);">
                            <!-- <div class="kt-input-icon kt-input-icon--left col-sm-5">
                                <input type="text" v-model="search" class="form-control" placeholder="Search...">
                                <span @click="searchUser" class="kt-input-icon__icon kt-input-icon__icon--left" style="cursor: pointer;">
                                    <span> <i class="material-icons">search</i> </span>
                                </span>
                            </div> -->
                            <div class="menu" style="float: right;">
                                <sim-dropdown>
                                    <div class="btn">
                                        <i class="material-icons">more_horiz</i>
                                    </div>
                                    <template #menu>
                                        <sim-dropdown-item  v-for="(column, key) in columns.filter(el => el.title !== '')" :key="key" @click="column.enabled= !column.enabled" >
                                            <template #icon>
                                                <i class="material-icons" v-if="column.enabled">visibility</i>
                                                <i class="material-icons" v-else>visibility_off</i>
                                            </template>
                                            <template #label>
                                            {{ column.title }}
                                            </template>
                                        </sim-dropdown-item>
                                    </template>
                                </sim-dropdown>
                            </div>
                        </div>
                        <div style="float: left; width: 192px;">
                            <sim-button align="right" :to="{ name: 'point_settings_make' }" :disabled="loading" :loading="loading">
                                <i class="material-icons"> add</i>
                                Crear Puntuación
                            </sim-button>
                        </div>
                    </div>

                    <!-- aqui va la tabla -->
                    <sim-table :columns="columns.filter(el => el.enabled)" :source="pagination.data" @change="orderBy" bordered>

                        <template #td:listCategories="{ record }">
                            <div v-if="record.item.categories.length > 0">
                                <span v-for="(category, categories_key) in record.item.categories" :key="categories_key">
                                    {{category.name}}
                                </span>
                            </div>
                            <div v-else>
                                Todas las Categorias
                            </div>
                        </template>

                        <template #td:listBrands="{ record }">
                            <div v-if="record.item.brands.length > 0">
                                <span v-for="(brand, brands_key) in record.item.brands" :key="brands_key">
                                    {{brand.name}}
                                </span>
                            </div>
                            <div v-else>
                                Todas las marcas
                            </div>
                        </template>

                        <template #td:listBrandsTypes="{ record }">
                            <div v-if="record.item.brand_types.length > 0">
                                <span v-for="(brand_type, brand_types_key) in record.item.brand_types" :key="brand_types_key">
                                    {{brand_type.name}}
                                </span>
                            </div>
                            <div v-else>
                                 Todos los Tipos de marcas
                            </div>
                        </template>

                        <template #td:actions="{ record }">
                            <router-link :to="{ name: 'edit_point_settings', params: { point_settings_id: record.item.id } }" style="margin-right: 10px;" class="btn btn-label-warning text-uppercase">
                                <i class="material-icons">edit</i>
                                editar
                            </router-link> 
                            <label v-if="record.item.brand_types.length > 0 && record.item.brands.length > 0 && record.item.categories.length > 0" class="btn btn-label-danger text-uppercase" @click="deletePoint({ id: record.item.id, back: false })">
                                <i class="material-icons"> delete</i>
                                Eliminar
                            </label>
                        </template>
                    </sim-table>
                    <sim-laravel-pagination align="right" :data="pagination" @change="fetchPointSettingss({ with:'categories,brands,brandTypes', page: $event, ...query })" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import Loader from '../../components/Loader';


export default {
    data: () => ({
        columns: [
            { title: 'Cantidad', key: 'value', enabled: true, style: {width: '40px'} },
            { title: 'Categorias', slot: 'listCategories', enabled: true },
            { title: 'Marcas', slot: 'listBrands', enabled: true },
            { title: 'Tipos de marcas', slot: 'listBrandsTypes', enabled: true },
            { title: 'Acciones', slot: 'actions', alignment: 'right', enabled: true, style: {width: '242px'} },
        ],
        limit: 10,
        query: {
            limit: 10,
            type: 5,
            administrators: true
        },
        search:'',
        isLoading: false,
    }),
    components: {
        Loader,
    },
    computed: {
        ...mapState({
            pagination: state => state.pointSettings.pagination,
            loading: state => state.pointSettings.loading,
        })
    },
    methods: {
        ...mapMutations({
            setNavbarTitle: 'SET_NAVBAR_TITLE'
        }),
        ...mapActions({
            fetchPointSettingss: 'pointSettings/fetchPointSettingss',
            deletePoint: 'pointSettings/delete'
        }),
        searchUser(){
            this.isLoading = true;
            this.fetchPointSettingss({with:'categories,brands,brandTypes,',search: this.search, page:1}).finally(() => {
                this.isLoading = false;
            });
        },
        orderBy(data){
            this.isLoading = true;

            if( this.search !== ''){
                this.fetchPointSettingss({with:'categories,brands,brandTypes', search: this.search, sort_by: `${data.field},${data.order}`, page:1}).finally(() => {
                    this.isLoading = false;
                });
            } else{
                this.fetchPointSettingss({with:'categories,brands,brandTypes', sort_by: `${data.field},${data.order}`, page:1}).finally(() => {
                    this.isLoading = false;
                });
            }
        }

    },
    mounted() {
        this.isLoading = true;
        this.fetchPointSettingss({with:'categories,brands,brandTypes', page:1}).finally(() => {
            this.isLoading = false;
        });
        //this.fetchPointSettingss({ page:1});
    },
    created() {
        this.setNavbarTitle(['Listado de Puntuacion'])
    },
    watch:{
        search (val) {
            if (val.length > 3 ) {
                this.searchUser()
            }
        }
    }
}
</script>