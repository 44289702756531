<template>
    <div class="row kt-pt25">
        <div class="col-12 col-sm-12">
            <div class="kt-portlet kt-portlet--height-fluid" style="height: auto;">
                <div v-if="isLoading">
                    <Loader />
                </div>
                <div v-else class="kt-portlet__body svg_list_trainig">
                    <div style="float: left; width: 100%;">
                        <div style="float: left; width: calc(100% - 236px);">
                            <!-- <div class="kt-input-icon kt-input-icon--left col-sm-5">
                                <input type="text" v-model="search" class="form-control" placeholder="Search...">
                                <span @click="searchUser" class="kt-input-icon__icon kt-input-icon__icon--left" style="cursor: pointer;">
                                    <span> <i class="material-icons">search</i> </span>
                                </span>
                            </div> -->
                            <div class="menu" style="float: right;">
                                <sim-dropdown>
                                    <div class="btn">
                                        <i class="material-icons">more_horiz</i>
                                    </div>
                                    <template #menu>
                                        <sim-dropdown-item  v-for="(column, key) in columns" :key="key" @click="column.enabled= !column.enabled" >
                                            <template #icon>
                                                <i class="material-icons" v-if="column.enabled">visibility</i>
                                                <i class="material-icons" v-else>visibility_off</i>
                                            </template>
                                            <template #label>
                                            {{ column.title }}
                                            </template>
                                        </sim-dropdown-item>
                                    </template>
                                </sim-dropdown>
                            </div>
                        </div>
                        <div style="float: left; width: 236px;">
                            <sim-button align="right" @click="modal = true" :disabled="loading" :loading="loading">
                                <i class="material-icons"> add</i>
                                {{l.app.l_t_b_c}}
                            </sim-button>
                        </div>
                    </div>
                    <!-- aqui va la tabla -->
                    <sim-table :columns="columns.filter(el => el.enabled)" :source="pagination.data"  @change="orderBy" bordered>
                        <template #td:enabled="{ record }">
                            <span v-if="record.item.enabled" class="material-icons icon_table">done</span>
                            <span v-else class="material-icons icon_table">clear</span>
                        </template>
                        <template #td:actions="{ record }">
                            <label style="margin-right: 10px;" class="btn btn-label-warning text-uppercase" @click="editSelecte( record.item )">
                                <i class="material-icons">edit</i>
                            </label> 
                            <label class="btn btn-label-danger text-uppercase" @click="deleteBrandTypes({ id: record.item.id, back: false })">
                                <i class="material-icons"> delete</i>
                            </label>
                        </template>
                    </sim-table>

                    <sim-laravel-pagination align="right" :data="pagination" @change="fetchdataForms({ page: $event, ...query })" />
                </div>
            </div>
        </div>
        <sim-Modal :show="modal" @close="modal = false">
            <h3 slot="header">Tipos de  Marcas</h3>
            <div class="form-group">
                <label for="name">Nombres</label>
                <sim-input
                    id="name"
                    placeholder="Nombres"
                    :error="error('name', { attribute: 'nombres' })"
                    v-model="dataForm.name"/>
            </div>

            <div class="form-group">
                <label for="type">Estado</label>
                <sim-select
                    :source="source"
                    v-model="dataForm.enabled" />
            </div>
            <template slot="footer">
                <sim-button class="mr-2" @click="save()" :loading="loading">
                    <i class="material-icons">save</i>
                    Guardar cambios
                </sim-button>

                <sim-button outlined @click="modal = false">
                    <i class="material-icons">clear</i>
                    Cancelar
                </sim-button>
            </template>

        </sim-Modal>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import Loader from '../../components/Loader';


export default {
    data: () => ({
        modal: false,
        columns: [],
        limit: 10,
        query: {
            limit: 10,
            type: 5,
            dataForms: true
        },
        search:'',
        dataForm:{
            name: '',
            enabled: true,
        },
        source:[],
        isLoading: false,

    }),
    components: {
        Loader,
    },
    computed: {
        ...mapGetters({
            hasError: 'hasError',
            error: 'error',
            l: 'lang'
        }),
        ...mapState({
            pagination: state => state.brandTypes.pagination,
            loading: state => state.brandTypes.loading
        })
    },
    methods: {
        ...mapMutations({
            setNavbarTitle: 'SET_NAVBAR_TITLE'
        }),
        ...mapActions({
            fetchBrandTypes: 'brandTypes/fetchBrandTypes',
            deleteBrandTypes: 'brandTypes/delete',
            store: 'brandTypes/store',
            update: 'brandTypes/update'
        }),
        editSelecte (data){
            this.modal = true;
            this.dataForm = data;
        },
        searchUser(){
            this.isLoading = true;
            if( this.search !== ''){
                this.fetchBrandTypes({search: this.search, page:1}).finally(() => {
                    this.isLoading = false;
                });
            }
        },
        orderBy(data){
            this.isLoading = true;
            if( this.search !== ''){
                this.fetchBrandTypes({search: this.search, sort_by: `${data.field},${data.order}`, page:1}).finally(() => {
                    this.isLoading = false;
                });
            } else{
                this.fetchBrandTypes({ sort_by: `${data.field},${data.order}`, page:1}).finally(() => {
                    this.isLoading = false;
                });
            }
        },
        async save () {
            this.isLoading = true;
            if( this.dataForm.hasOwnProperty('id') ){
                await this.update(this.dataForm);
            }else {
                await  this.store(this.dataForm);
            }
            this.modal = false;
            this.dataForm = {
                name: '',
                enabled: true
            };
            this.fetchBrandTypes().finally(() => {
                this.isLoading = false;
            });
        }
    },
    mounted() {
        this.isLoading = true;
        this.columns =[
            { title: this.l.app.Name, key: 'name', enabled: true, sorter:false },
            { title: this.l.app.State, key: 'enabled', slot: 'enabled', alignment: 'right', enabled: true, style: {width: '60px'}, sorter:false },
            { title: this.l.app.Actions, slot: 'actions', alignment: 'right', enabled: true, style: {width: '242px'} },
        ];
        this.fetchBrandTypes().finally(() => {
            this.isLoading = false;
        });
    },
    created() {
        this.setNavbarTitle([this.l.app.l_t_b])
    },
    watch:{
        search (val) {
            if (val.length > 3 ) {
                this.searchUser()
            }
        },
        modal(val){
            if(val){
                this.source = [{ label: 'Activo', value: 1 }, { label: 'No Activo', value: 0 }]
            }
            else{
                this.source = []
                this.dataForm = {
                    name: '',
                    enabled: true
                };
            }
        }
    }
}
</script>
