<template>
    <div class="row kt-pt25">
        <div class="col-12 col-sm-12">
            <div class="kt-portlet kt-portlet--height-fluid" style="height: auto;">
                <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item" @click="tabs = 2">
                        <div :class="['nav-link', tabs === 2 && 'active']">
                            <span class="m--visible-desktop-inline-block">
                                {{l.app.GOWN}}
                            </span>
                        </div>
                    </li>
                    <li class="nav-item" @click="tabs = 3">
                        <div :class="['nav-link', tabs === 3 && 'active']">
                            <span class="m--visible-desktop-inline-block">
                                {{l.app.HAUTE_COUTURE}}
                            </span>
                        </div>
                    </li>
                </ul>
                <div v-if="isLoading">
                    <Loader />
                </div>
                <div v-else class="kt-portlet__body svg_list_trainig">
                    <div style="float: left; width: 100%;">
                        <div style="float: left; width: calc(100% - 160px);">
                            <div style="float: left; max-width: 400px;" class="kt-input-icon kt-input-icon--left col-sm-5">
                                <input type="text" v-model="search" class="form-control" :placeholder="l.app.Search">
                                <span @click="searchUser" class="kt-input-icon__icon kt-input-icon__icon--left" style="cursor: pointer;">
                                    <span> <i class="material-icons">search</i> </span>
                                </span>
                            </div>
                            <div class="menu" style="float: right;right: 0;top: 0;">
                                <sim-dropdown>
                                    <div class="btn">
                                        <i class="material-icons">more_horiz</i>
                                    </div>
                                    <template #menu>
                                        <sim-dropdown-item  v-for="(column, key) in columns.filter(el => el.title !== '')" :key="key" @click="column.enabled= !column.enabled" >
                                            <template #icon>
                                                <i class="material-icons" v-if="column.enabled">visibility</i>
                                                <i class="material-icons" v-else>visibility_off</i>
                                            </template>
                                            <template #label>
                                            {{ column.title }}
                                            </template>
                                        </sim-dropdown-item>
                                    </template>
                                </sim-dropdown>
                            </div>
                            <div style="float: left; width: 100%; padding-top: 10px;">
                                <span  :class="['btn-filter', status === '' && 'active']" @click="status = ''">{{l.app.ALL}}</span>
                                <span :class="['btn-filter', status === 0 && 'active']" @click="status = 0">{{l.app.Initial_state}}</span>
                                <span :class="['btn-filter', status === 1 && 'active']" @click="status = 1">{{l.app.Garment_in_transaction}}</span>
                                <span :class="['btn-filter', status === 2 && 'active']" @click="status = 2">{{l.app.Approved_in_physical_review}}</span>
                                <span :class="['btn-filter', status === 3 && 'active']" @click="status = 3">{{l.app.Rejected_in_physical_review}}</span>
                                <span :class="['btn-filter', status === 4 && 'active']" @click="status = 4">{{l.app.Release}}</span>
                                <span :class="['btn-filter', status === 5 && 'active']" @click="status = 5">{{l.app.Garment_in_an_order}}</span>
                                <span :class="['btn-filter', status === 6 && 'active']" @click="status = 6">{{l.app.Order_completed_the_garment_was_sold}}</span>
                            </div>
                            <div style="float: left; width: 100%; padding-top: 10px;">
                                <span  :class="['btn-filter', upload_by === '' && 'active']" @click="upload_by = ''">{{l.app.ALL}}</span>
                                <span  :class="['btn-filter', upload_by === 'customer' && 'active']" @click="upload_by = 'customer'">{{l.app.customers}}</span>
                                <span  :class="['btn-filter', upload_by === 'admin' && 'active']" @click="upload_by = 'admin'">{{l.app.administrators}}</span>
                                
                            </div>
                        </div>
                        <div style="float: left; width: 160px;">
                            <sim-button align="right" :to="{ name: 'garments_make' }" :disabled="loading" :loading="loading">
                                <i class="material-icons"> add</i>
                                {{l.app.Create_Garment}}
                            </sim-button>
                        </div>
                    </div>

                    <!-- aqui va la tabla -->
                    <sim-table :columns="columns.filter(el => el.enabled)" :source="pagination.data" @change="orderBy" bordered>
                        <template #td:seleted="{ record }">
                            <sim-checkbox v-model="record.item.selected"/>
                        </template>
                        <template #td:img="{ record }">
                            <span v-if="record.item.picture_urls.length > 0" style="width: 40px; height: 40px; border-radius: 50%; background: #ddd; display: block; margin-left: 10px; overflow: hidden;">
                                <img :src="record.item.picture_urls[0].replace(/\.([^\.]*)$/, '_80x112.' + '$1')" alt="" style="width: 100%; height: 100%;">
                            </span>
                            <span v-else class="material-icons" style="width: 40px; height: 40px; border-radius: 50%; background: #ddd; display: block; margin-left: 10px; overflow: hidden; text-align: center; line-height: 40px;">
                                image_not_supported
                            </span>
                        </template>
                        <template #td:transactions="{ record }">
                            <span v-if="record.item.transaction_id === null && record.item.status > 2">
                                {{l.app.admin}}
                            </span>
                            <span v-if="record.item.transaction_id === null && record.item.status < 2">
                               {{l.app.customer}}
                            </span>
                            <span v-if="record.item.transaction_id !== null" class="w-100 img-product relative">
                                 <div class="w-100 img-product relative" style="width: 40px; float: left;">
                                    <img :src="record.item.customer_profile_picture" class="img-responsive" style="border-radius: 100%;"/>
                                </div>
                                <div class="w-100 info-product" style="width: calc(100% - 40px); padding: 0 20px; float: left;"> 
                                    <div class="w-100 info-product"> <b> {{l.app.transaction}} #{{record.item.transaction_number}} </b> </div>
                                    <div class="w-100 info-product"> <b> {{record.item.customer_email}} </b> </div>
                                    <div class="w-100 info-product"> <b> {{record.item.customer_first_name}} {{record.item.customer_last_name}} </b> </div>
                                </div>
                            </span>
                        </template>
                        <template #td:type="{ record }">
                            <span v-if="record.item.type === 1">{{l.app.CLOTHING}}</span>
                            <span v-if="record.item.type === 2">{{l.app.GOWN}}</span>
                            <span v-if="record.item.type === 3">{{l.app.HAUTE_COUTURE}}</span>
                            <span v-if="record.item.type === 4">{{l.app.DESIGNER_BAG}}</span>
                        </template>
                        <template #td:active="{ record }">
                            <span v-if="record.item.status === 0">{{l.app.Initial_state}}</span>
                            <span v-if="record.item.status === 1">{{l.app.Garment_in_transaction}}</span>
                            <span v-if="record.item.status === 2">{{l.app.Approved_in_physical_review}}</span>
                            <span v-if="record.item.status === 3">{{l.app.Rejected_in_physical_review}}</span>
                            <span v-if="record.item.status === 4">{{l.app.Release}}</span>
                            <span v-if="record.item.status === 5">{{l.app.Garment_in_an_order}}</span>
                            <span v-if="record.item.status === 6">{{l.app.Order_completed_the_garment_was_sold}}</span>
                        </template>
                        <template #td:actions="{ record }">
                            <sim-dropdown right fixed>
                                <span class="material-icons">more_vert</span>
                                <template #header>
                                    {{l.app.Actions}}
                                </template>
                                <template #menu>
                                    <sim-dropdown-item v-if="record.item.status === 2" :to="{ name: 'edit_garment', params: { garment_id: record.item.id } }" class="text-uppercase">
                                        <template #icon>
                                            <i class="material-icons" style="color: #FE9801;">edit</i>
                                        </template>
                                        <template #label>
                                            {{l.app.Edit}}
                                        </template>
                                    </sim-dropdown-item>
                                    <sim-dropdown-item  @click="deleteGarments({ id: record.item.id, back: false })" class="text-uppercase" >
                                        <template #icon>
                                            <i class="material-icons" style="color: #FF6565;">delete</i>
                                        </template>
                                        <template #label>
                                            {{l.app.Remove}}
                                        </template>
                                    </sim-dropdown-item>

                                    <sim-dropdown-item v-if="record.item.status === 2 && record.item.points_value !== null" @click="set_on_sale(record.item.id)" class="text-uppercase" >
                                        <template #icon>
                                            <i class="material-icons" style="color: #626AC9;">shopping_cart</i>
                                        </template>
                                        <template #label>
                                            {{l.app.Set_for_sale}}
                                        </template>
                                    </sim-dropdown-item>
                                    <sim-dropdown-item v-if="record.item.status === 4" @click="not_set_on_sale(record.item.id)" class="text-uppercase" >
                                        <template #icon>
                                            <i class="material-icons" style="color: #626AC9;">remove_shopping_cart</i>
                                        </template>
                                        <template #label>
                                            {{l.app.Remove_from_store}}
                                        </template>
                                    </sim-dropdown-item>
                                </template>
                            </sim-dropdown>
                        </template>
                    </sim-table>

                    <sim-laravel-pagination align="right" :data="pagination" @change="fetchGarments({ page: $event, ...query })" />
                </div>
            </div>
        </div>
        <sim-Modal :show="modal" @close="modal = false">
            <h3 slot="header">
                <b class="col-12 title-detail-transaction" style="font-size: 28px; margin-bottom: 20px;">
                    {{l.app.Put_the_price_in_points_for_approval}}
                </b>
            </h3>
            <div class="row">
                <sim-input class="col-12" id="updatePoint" type="number" v-model="updatePoint"/>
            </div>
            <template slot="footer">
                <sim-button class="mr-2" @click="sentUpdateGarmentSetAccepted()" :loading="loading">
                    <i class="material-icons">save</i>
                   {{l.app.Save_Changes}}
                </sim-button>
                <sim-button outlined @click="modal = false; updatePoint= 5; garments= null">
                    <i class="material-icons">clear</i>
                    {{l.app.Cancel}}
                </sim-button>
            </template>
        </sim-Modal>
    </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import moment from 'moment';
import { 
    updateGarmentSetAccepted,
    updateGarmentSetRejected
} from '@/api'
import Loader from '../../components/Loader';


export default {
    data: () => ({
        columns:[],
        tabs: 2,
        status: '',
        upload_by: '',
        limit: 10,
        query: {
            limit: 10,
            type: 2,
            upload_by:'',
            status:'',
            with:'pictures,transaction.customer',
            sort_by: 'updated_at,desc'
        },
        search:'',
        modal: false,
        updatePoint: 5,
        garments: null,
        isLoading: false,

    }),
    components: {
        Loader,
    },
    computed: {
        ...mapState({
            pagination: state => state.garments.pagination,
            loading: state => state.garments.loading,
        }),
        ...mapGetters({
            l: 'lang'
        })
    },
    methods: {
        ...mapMutations({
            setNavbarTitle: 'SET_NAVBAR_TITLE'
        }),
        ...mapActions({
            fetchGarmentsActions: 'garments/fetchGarments',
            deleteGarments: 'garments/delete',
            set_on_sale_store: 'garments/set_on_sale'
        }),
        searchUser(){
            this.query.search = this.search;
            this.fetchGarments({ page:1, ...this.query })
        },
        orderBy(data){
            this.isLoading = true;
            if( this.search !== ''){
                this.query.search = this.search;
                this.query.sort_by= `${data.field},${data.order}`;
                this.fetchGarments({ page:1, ...this.query }).finally(() => {
                    this.isLoading = false;
                });
            } else{
                this.query.sort_by= `${data.field},${data.order}`;
                this.fetchGarments({ page:1, ...this.query }).finally(() => {
                    this.isLoading = false;
                });
            }
        },
        fetchGarments(query){
            this.isLoading = true;
            this.fetchGarmentsActions(query).then( () =>{
                this.$router.push({ query });
            }).finally(() => {
                this.isLoading = false;
            });
        },
        set_on_sale(id){
            this.isLoading = true;
            if (confirm(this.l.app.Do_you_want_to_put_this_garment_up_for_sale)) {
                this.set_on_sale_store({id:id, data:{"on_sale": true}}).then(()=>{
                    this.fetchGarments({ page:1, ...this.query }).finally(() => {
                        this.isLoading = false;
                    });
                })
            }
        },
        not_set_on_sale(id){
            this.isLoading = true;
            if (confirm(this.l.app.Want_to_remove_from_the_for_sale_list)) {
                this.set_on_sale_store({id:id, data:{"on_sale": false}}).then(()=>{
                    this.fetchGarments({ page:1, ...this.query }).finally(() => {
                        this.isLoading = false;
                    });
                })
            }
        },
        updateGarmentSetAccepted(id){
            this.modal=true;
            this.garments= id;
        },
        sentUpdateGarmentSetAccepted(){
            updateGarmentSetAccepted(this.garments,{ points: this.updatePoint}).then( () => {
                this.modal=false;
                this.garments= null;
            })
        },
        updateGarmentSetRejected(id){
            updateGarmentSetRejected(id,{ points: this.updatePoint})
        }
    },
    mounted() {
        this.isLoading = true;

        if(this.$route.query.page !== undefined){
            if(this.$route.params.tabs != undefined) {
                this.$route.query.type = this.$route.params.tabs;
            }

            this.fetchGarments(this.$route.query);
        } else {
            if(this.$route.params.tabs != undefined) {
                this.query.type = this.$route.params.tabs;
            }

            this.fetchGarments({ page:1, ...this.query });
        }
    },
    created() {
        this.setNavbarTitle([this.l.app.Garment_List]);
        this.columns= [
                { title: '', slot: 'seleted', alignment: 'right', enabled: false, style: {width: '29px'} },
                { title: '', slot: 'img', alignment: 'right', enabled: true, style: {width: '40px'} },
                { title: this.l.app.columnsGarment.c_G_1 , key: 'name', enabled: true },
                { title: this.l.app.columnsGarment.c_G_2 , key: 'type', slot: 'type', enabled: true, style: {width: '50px'}, sorter:true },
                { title: this.l.app.columnsGarment.c_G_3 , key: 'description', enabled: false },
                { title: this.l.app.columnsGarment.c_G_4 , key: 'size', enabled: true, style: {width: '40px'}, sorter:true },
                { title: this.l.app.columnsGarment.c_G_5 , key: 'points_value', enabled: true, style: {width: '40px'}, sorter:true },
                { title: this.l.app.columnsGarment.c_G_6 , key: 'status', slot: 'active', alignment: 'right', enabled: true, sorter:true },
                { title: this.l.app.columnsGarment.c_G_7 , key: 'created_at_format', enabled: true },
                { title: this.l.app.columnsGarment.c_G_8 , slot: 'actions', alignment: 'right', enabled: true, style: {width: '242px'} },
            ]
    },
    watch:{
        search () {
            this.searchUser()
        },
        pagination(val){
            val.data.forEach(value => {
                if(value.transaction_id !== null){
                    value.transaction_number = value.transaction.number
                    value.customer_profile_picture = value.transaction.customer.profile_picture
                    value.customer_email = value.transaction.customer.email
                    value.customer_first_name = value.transaction.customer.first_name
                    value.customer_last_name = value.transaction.customer.last_name
                }
                value.created_at_format = moment(value.created_at).format('ll');
            });
        },
        tabs(val){
            this.query.type= val;
            this.fetchGarments(this.query);
        },
        status(val){
            this.query.status= val;
            this.fetchGarments(this.query);
        },
        upload_by(val){
            this.query.upload_by= val;
            this.fetchGarments(this.query);
        }
    }
}
</script>
