var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row kt-pt25"},[_c('div',{staticClass:"col-12 col-sm-12"},[_c('div',{staticClass:"kt-portlet kt-portlet--height-fluid",staticStyle:{"height":"auto"}},[(_vm.isLoading)?_c('div',[_c('Loader')],1):_c('div',{staticClass:"kt-portlet__body svg_list_trainig"},[_c('div',{staticStyle:{"float":"left","width":"100%"}},[_c('div',{staticStyle:{"float":"left","width":"calc(100% - 192px)"}},[_c('div',{staticClass:"menu",staticStyle:{"float":"right"}},[_c('sim-dropdown',{scopedSlots:_vm._u([{key:"menu",fn:function(){return _vm._l((_vm.columns.filter(function (el) { return el.title !== ''; })),function(column,key){return _c('sim-dropdown-item',{key:key,on:{"click":function($event){column.enabled= !column.enabled}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(column.enabled)?_c('i',{staticClass:"material-icons"},[_vm._v("visibility")]):_c('i',{staticClass:"material-icons"},[_vm._v("visibility_off")])]},proxy:true},{key:"label",fn:function(){return [_vm._v(" "+_vm._s(column.title)+" ")]},proxy:true}],null,true)})})},proxy:true}])},[_c('div',{staticClass:"btn"},[_c('i',{staticClass:"material-icons"},[_vm._v("more_horiz")])])])],1)]),_c('div',{staticStyle:{"float":"left","width":"192px"}},[_c('sim-button',{attrs:{"align":"right","to":{ name: 'point_settings_make' },"disabled":_vm.loading,"loading":_vm.loading}},[_c('i',{staticClass:"material-icons"},[_vm._v(" add")]),_vm._v(" Crear Puntuación ")])],1)]),_c('sim-table',{attrs:{"columns":_vm.columns.filter(function (el) { return el.enabled; }),"source":_vm.pagination.data,"bordered":""},on:{"change":_vm.orderBy},scopedSlots:_vm._u([{key:"td:listCategories",fn:function(ref){
var record = ref.record;
return [(record.item.categories.length > 0)?_c('div',_vm._l((record.item.categories),function(category,categories_key){return _c('span',{key:categories_key},[_vm._v(" "+_vm._s(category.name)+" ")])}),0):_c('div',[_vm._v(" Todas las Categorias ")])]}},{key:"td:listBrands",fn:function(ref){
var record = ref.record;
return [(record.item.brands.length > 0)?_c('div',_vm._l((record.item.brands),function(brand,brands_key){return _c('span',{key:brands_key},[_vm._v(" "+_vm._s(brand.name)+" ")])}),0):_c('div',[_vm._v(" Todas las marcas ")])]}},{key:"td:listBrandsTypes",fn:function(ref){
var record = ref.record;
return [(record.item.brand_types.length > 0)?_c('div',_vm._l((record.item.brand_types),function(brand_type,brand_types_key){return _c('span',{key:brand_types_key},[_vm._v(" "+_vm._s(brand_type.name)+" ")])}),0):_c('div',[_vm._v(" Todos los Tipos de marcas ")])]}},{key:"td:actions",fn:function(ref){
var record = ref.record;
return [_c('router-link',{staticClass:"btn btn-label-warning text-uppercase",staticStyle:{"margin-right":"10px"},attrs:{"to":{ name: 'edit_point_settings', params: { point_settings_id: record.item.id } }}},[_c('i',{staticClass:"material-icons"},[_vm._v("edit")]),_vm._v(" editar ")]),(record.item.brand_types.length > 0 && record.item.brands.length > 0 && record.item.categories.length > 0)?_c('label',{staticClass:"btn btn-label-danger text-uppercase",on:{"click":function($event){return _vm.deletePoint({ id: record.item.id, back: false })}}},[_c('i',{staticClass:"material-icons"},[_vm._v(" delete")]),_vm._v(" Eliminar ")]):_vm._e()]}}])}),_c('sim-laravel-pagination',{attrs:{"align":"right","data":_vm.pagination},on:{"change":function($event){return _vm.fetchPointSettingss(Object.assign({}, {with:'categories,brands,brandTypes', page: $event}, _vm.query))}}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }