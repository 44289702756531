var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row kt-pt25"},[_c('div',{staticClass:"col-12 col-sm-12"},[_c('div',{staticClass:"kt-portlet kt-portlet--height-fluid",staticStyle:{"height":"auto"}},[(_vm.isLoading)?_c('div',[_c('Loader')],1):_c('div',{staticClass:"kt-portlet__body svg_list_trainig"},[_c('div',{staticStyle:{"float":"left","width":"100%"}},[_c('div',{staticStyle:{"float":"left","width":"calc(100% - 158px)"}},[_c('div',{staticClass:"menu",staticStyle:{"float":"right"}},[_c('sim-dropdown',{scopedSlots:_vm._u([{key:"menu",fn:function(){return _vm._l((_vm.columns.filter(function (el) { return el.title !== ''; })),function(column,key){return _c('sim-dropdown-item',{key:key,on:{"click":function($event){column.enabled= !column.enabled}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(column.enabled)?_c('i',{staticClass:"material-icons"},[_vm._v("visibility")]):_c('i',{staticClass:"material-icons"},[_vm._v("visibility_off")])]},proxy:true},{key:"label",fn:function(){return [_vm._v(" "+_vm._s(column.title)+" ")]},proxy:true}],null,true)})})},proxy:true}])},[_c('div',{staticClass:"btn"},[_c('i',{staticClass:"material-icons"},[_vm._v("more_horiz")])])])],1)]),_c('div',{staticStyle:{"float":"left","width":"158px"}},[_c('sim-button',{attrs:{"align":"right","to":{ name: 'customers_make' },"disabled":_vm.loading,"loading":_vm.loading}},[_c('i',{staticClass:"material-icons"},[_vm._v(" add")]),_vm._v(" "+_vm._s(_vm.l.app.Create_Client)+" ")])],1)]),_c('sim-table',{attrs:{"columns":_vm.columns.filter(function (el) { return el.enabled; }),"source":_vm.pagination.data,"bordered":""},on:{"change":_vm.orderBy},scopedSlots:_vm._u([{key:"td:img",fn:function(ref){
var record = ref.record;
return [_c('span',{staticStyle:{"width":"40px","height":"40px","border-radius":"50%","background":"#ddd","display":"block","margin-left":"10px","overflow":"hidden"}},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":record.item.profile_picture,"alt":""}})])]}},{key:"td:name",fn:function(ref){
var record = ref.record;
return [_vm._v(" "+_vm._s(record.item.first_name)+" "+_vm._s(record.item.last_name)+" ")]}},{key:"td:fit_type",fn:function(ref){
var record = ref.record;
return [(record.item.fit_type === 1)?_c('samp',[_vm._v("Normal (true to size)")]):(record.item.fit_type === 2)?_c('samp',[_vm._v("Ajustado (tight)")]):_c('samp',[_vm._v("Grande (loose)")])]}},{key:"td:created_at_format",fn:function(ref){
var record = ref.record;
return [_c('p',[_vm._v(_vm._s(record.item.created_at_format))])]}},{key:"td:actions",fn:function(ref){
var record = ref.record;
return [_c('sim-dropdown',{attrs:{"right":"","fixed":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.l.app.Actions)+" ")]},proxy:true},{key:"menu",fn:function(){return [_c('sim-dropdown-item',{staticClass:"text-uppercase",attrs:{"to":{ name: 'edit_customer', params: { customer_id: record.item.id } }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('i',{staticClass:"material-icons",staticStyle:{"color":"#FE9801"}},[_vm._v("edit")])]},proxy:true},{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.l.app.Edit)+" ")]},proxy:true}],null,true)}),_c('sim-dropdown-item',{staticClass:"text-uppercase",on:{"click":function($event){return _vm.deleteUser({ id: record.item.id, back: false })}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('i',{staticClass:"material-icons",staticStyle:{"color":"#FF6565"}},[_vm._v("delete")])]},proxy:true},{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.l.app.Remove)+" ")]},proxy:true}],null,true)})]},proxy:true}],null,true)},[_c('span',{staticClass:"material-icons"},[_vm._v("more_vert")])])]}}])}),_c('sim-laravel-pagination',{attrs:{"align":"right","data":_vm.pagination},on:{"change":_vm.pages}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }