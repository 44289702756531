<template>
    <div class="row kt-pt25">
        <div class="col-12 col-sm-12">
            <div class="kt-portlet kt-portlet--height-fluid" style="height: auto;">
                <div v-if="isLoading">
                    <Loader />
                </div>
                <div v-else class="kt-portlet__body svg_list_trainig">
                    <div style="float: left; width: 100%;">
                        <div style="float: left; width: calc(100% - 158px);">
                            <!-- <div class="kt-input-icon kt-input-icon--left col-sm-5">
                                <input type="text" v-model="search" class="form-control" placeholder="Search...">
                                <span @click="searchUser" class="kt-input-icon__icon kt-input-icon__icon--left" style="cursor: pointer;">
                                    <span> <i class="material-icons">search</i> </span>
                                </span>
                            </div> -->
                            <div class="menu" style="float: right;">
                                <sim-dropdown>
                                    <div class="btn">
                                        <i class="material-icons">more_horiz</i>
                                    </div>
                                    <template #menu>
                                        <sim-dropdown-item  v-for="(column, key) in columns.filter(el => el.title !== '')" :key="key" @click="column.enabled= !column.enabled" >
                                            <template #icon>
                                                <i class="material-icons" v-if="column.enabled">visibility</i>
                                                <i class="material-icons" v-else>visibility_off</i>
                                            </template>
                                            <template #label>
                                            {{ column.title }}
                                            </template>
                                        </sim-dropdown-item>
                                    </template>
                                </sim-dropdown>
                            </div>
                        </div>
                        <div style="float: left; width: 158px;">
                            <sim-button align="right" :to="{ name: 'customers_make' }" :disabled="loading" :loading="loading">
                                <i class="material-icons"> add</i>
                                {{l.app.Create_Client}}
                            </sim-button>
                        </div>
                    </div>
                    <!-- aqui va la tabla -->
                    <sim-table :columns="columns.filter(el => el.enabled)" :source="pagination.data" @change="orderBy" bordered>
                        <template #td:img="{ record }">
                            <span style="width: 40px; height: 40px; border-radius: 50%; background: #ddd; display: block; margin-left: 10px; overflow: hidden;">
                                <img :src="record.item.profile_picture" alt="" style="width: 100%; height: 100%;">
                            </span>
                        </template>
                        <template #td:name="{ record }">
                            {{ record.item.first_name }} {{ record.item.last_name }}
                        </template>
                        <template #td:fit_type="{ record }">
                            <samp v-if="record.item.fit_type === 1">Normal (true to size)</samp>
                            <samp v-else-if="record.item.fit_type === 2">Ajustado (tight)</samp>
                            <samp v-else>Grande (loose)</samp>
                        </template>
                        <template #td:created_at_format="{ record }">
                            <p>{{record.item.created_at_format}}</p>
                        </template>
                        <template #td:actions="{ record }">
                            <sim-dropdown right fixed>
                                <span class="material-icons">more_vert</span>
                                <template #header>
                                    {{l.app.Actions}}
                                </template>
                                <template #menu>
                                    <sim-dropdown-item :to="{ name: 'edit_customer', params: { customer_id: record.item.id } }" class="text-uppercase">
                                        <template #icon>
                                            <i class="material-icons" style="color: #FE9801;">edit</i>
                                        </template>
                                        <template #label>
                                            {{l.app.Edit}}
                                        </template>
                                    </sim-dropdown-item>
                                    <sim-dropdown-item  @click="deleteUser({ id: record.item.id, back: false })" class="text-uppercase" >
                                        <template #icon>
                                            <i class="material-icons" style="color: #FF6565;">delete</i>
                                        </template>
                                        <template #label>
                                            {{l.app.Remove}}
                                        </template>
                                    </sim-dropdown-item>
                                </template>
                            </sim-dropdown>
                        </template>
                    </sim-table>

                    <sim-laravel-pagination align="right" :data="pagination" @change="pages" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import moment from 'moment';
import Loader from '../../components/Loader';

export default {
    data: () => ({
        columns:[],
        limit: 10,
        query:{
            limit:10,
            search: '',
            sort_by:'created_at,desc',
            page:1
        },
        search:'',
        isLoading: false,

    }),
    components: {
        Loader,
    },
    computed: {
        ...mapState({
            pagination: state => state.customers.pagination,
            loading: state => state.customers.loading,
            session: state => state.session.user
        }),
        ...mapGetters({
            l: 'lang'
        })
    },
    methods: {
        ...mapMutations({
            setNavbarTitle: 'SET_NAVBAR_TITLE'
        }),
        ...mapActions({
            fetchCustomers: 'customers/fetchCustomers',
            deleteUser: 'customers/delete'
        }),
        searchUser(){
            this.fetchCustomers({search: this.search, page:1})
        },
        orderBy(data){
            this.isLoading = true;
            this.query.sort_by= `${data.field},${data.order}`
            this.fetchCustomers(this.query).finally(() => {
                this.isLoading = false;
            });
        },
        pages(e){
            this.isLoading = true;
            this.query.page=e
            this.fetchCustomers(this.query).finally(() => {
                this.isLoading = false;
            });
        }
    },
    mounted() {
        this.isLoading = true;
        this.fetchCustomers(this.query).finally(() => {
            this.isLoading = false;
        });
    },
    created() {
        this.setNavbarTitle([this.l.app.List_of_Clients]);
        this.columns= [
            { title: '', slot: 'img', alignment: 'right', enabled: true, style: {width: '40px'} },
            { title: this.l.app.columnsCustomer.c_c_1 , key: 'email', enabled: true, sorter:true },
            { title: this.l.app.columnsCustomer.c_c_2 , key: 'first_name', enabled: true, sorter:true },
            { title: this.l.app.columnsCustomer.c_c_3 , key: 'last_name', enabled: true, sorter:true },
            { title: this.l.app.columnsCustomer.c_c_4 , key: 'country_iso_code', enabled: true, sorter:true, style: {width: '100px'}},
            { title: this.l.app.columnsCustomer.c_c_5 , key: 'city', enabled: true, sorter:true },
            { title: this.l.app.columnsCustomer.c_c_6 , key: 'zip_code', enabled: false, sorter:true },
            { title: this.l.app.columnsCustomer.c_c_7 , key: 'phone', enabled: false, sorter:true },
            { title: this.l.app.columnsCustomer.c_c_8 , slot: 'fit_type', key: 'fit_type', enabled: false, sorter:true },
            { title: this.l.app.columnsCustomer.c_c_9 , slot: 'created_at_format', key: 'created_at', enabled: true, sorter:true },
            { title: '', slot: 'actions', alignment: 'right', enabled: true, style: {width: '42px'} },
        ]
    },
    watch:{
        search (val) {
            if (val.length > 3 ) {
                this.searchUser()
            }
        },
        pagination(val){
            val.data.forEach(value => {
                    value.created_at_format = moment(value.created_at).format('ll');
            });
        }
    }
}
</script>