<template>
    <div class="row kt-pt25">
        <div class="col-12 col-sm-12">
            <div class="kt-portlet kt-portlet--height-fluid" style="height: auto;">
                <div v-if="isLoading">
                    <Loader />
                </div>
                <div v-else class="kt-portlet__body svg_list_trainig">
                    <div style="float: left; width: 100%;">
                        <div style="float: left; width: calc(100% - 186px);">
                            <!-- <div class="kt-input-icon kt-input-icon--left col-sm-5">
                                <input type="text" v-model="search" class="form-control" placeholder="Search...">
                                <span @click="searchUser" class="kt-input-icon__icon kt-input-icon__icon--left" style="cursor: pointer;">
                                    <span> <i class="material-icons">search</i> </span>
                                </span>
                            </div> -->
                            <div class="menu" style="float: right;">
                                <sim-dropdown>
                                    <div class="btn">
                                        <i class="material-icons">more_horiz</i>
                                    </div>
                                    <template #menu>
                                        <sim-dropdown-item  v-for="(column, key) in columns.filter(el => el.title !== '')" :key="key" @click="column.enabled= !column.enabled" >
                                            <template #icon>
                                                <i class="material-icons" v-if="column.enabled">visibility</i>
                                                <i class="material-icons" v-else>visibility_off</i>
                                            </template>
                                            <template #label>
                                            {{ column.title }}
                                            </template>
                                        </sim-dropdown-item>
                                    </template>
                                </sim-dropdown>
                            </div>
                        </div>
                        <div style="float: left; width: 186px;">
                            <sim-button align="right" :to="{ name: 'categories_make' }" :disabled="loading" :loading="loading">
                                <i class="material-icons"> add</i>
                                Crear Categorías
                            </sim-button>
                        </div>
                    </div>

                    <!-- aqui va la tabla -->
                    <sim-table :columns="columns.filter(el => el.enabled)" :source="pagination.data" @change="orderBy" bordered>
                        <template #td:seleted="{ record }">
                            <sim-checkbox v-model="record.item.selected"/>
                        </template>
                        <template #td:img="{ record }">
                            <span style="width: 40px; height: 40px; border-radius: 50%; background: #ddd; display: block; margin-left: 10px; overflow: hidden;">
                                <img :src="record.item.picture" alt="" style="width: 100%; height: 100%;">
                            </span>
                        </template>
                        <template #td:active="{ record }">
                            <span v-if="record.item.enabled" class="material-icons icon_table">done</span>
                            <span v-else class="material-icons icon_table">clear</span>
                        </template>
                        <template #td:actions="{ record }">
                            <router-link :to="{ name: 'edit_catagory', params: { category_id: record.item.id } }" style="margin-right: 10px;" class="btn btn-label-warning text-uppercase">
                                <i class="material-icons">edit</i>
                                editar
                            </router-link> 
                            <label class="btn btn-label-danger text-uppercase" @click="deleteCategories({ id: record.item.id, back: false })">
                                <i class="material-icons"> delete</i>
                                Eliminar
                            </label>
                        </template>
                    </sim-table>

                    <sim-laravel-pagination align="right" :data="pagination" @change="fetchCategories({ page: $event, ...query })" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import Loader from '../../components/Loader';

// ID
// Nombre
// Descripción
// Activa/Inactiva

export default {
    data: () => ({
        columns: [
            { title: '', slot: 'seleted', alignment: 'right', enabled: false, style: {width: '29px'} },
            { title: '', slot: 'img', alignment: 'right', enabled: true, style: {width: '40px'} },
            { title: 'Nombre', key: 'name', enabled: true, sorter:true },
            { title: 'Descripción', key: 'description', enabled: true },
            { title: 'Estado', key: 'enabled', slot: 'active', alignment: 'right', enabled: true, style: {width: '60px'}, sorter:true },
            { title: 'Acciones', slot: 'actions', alignment: 'right', enabled: true, style: {width: '242px'} },
        ],
        limit: 10,
        query: {
            limit: 10,
            type: 5,
            administrators: true
        },
        search:'',
        isLoading: false,
    }),
    components: {
        Loader,
    },
    computed: {
        ...mapState({
            pagination: state => state.categories.pagination,
            loading: state => state.categories.loading,
        })
    },
    methods: {
        ...mapMutations({
            setNavbarTitle: 'SET_NAVBAR_TITLE'
        }),
        ...mapActions({
            fetchCategories: 'categories/fetchCategories',
            deleteCategories: 'categories/delete'
        }),
        searchUser(){
            this.fetchCategories({search: this.search, page:1}).finally(() => {
                    this.isLoading = false;
                });
        },
        orderBy(data){
            this.isLoading = true;
            if( this.search !== ''){
                this.fetchCategories({search: this.search, sort_by: `${data.field},${data.order}`, page:1}).finally(() => {
                    this.isLoading = false;
                });
            } else{
                this.fetchCategories({ sort_by: `${data.field},${data.order}`, page:1}).finally(() => {
                    this.isLoading = false;
                });
            }
        }

    },
    mounted() {
        this.isLoading = true;
        this.fetchCategories().finally(() => {
            this.isLoading = false;
        });
    },
    created() {
        this.setNavbarTitle(['Listado de Categorías'])
    },
    watch:{
        search (val) {
            if (val.length > 3 ) {
                this.searchUser()
            }
        }
    }
}
</script>
