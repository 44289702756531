<template>
    <div class="row kt-pt25">
        <div class="col-12 col-sm-12">
            <div class="kt-portlet kt-portlet--height-fluid" style="height: auto;">
                <div class="kt-portlet__body svg_list_trainig">
                    <div v-if="isLoading">
                        <Loader />
                    </div>
                    <div v-else style="float: left; width: 100%;">
                        <div style="float: left; width: calc(100% - 161px);">
                            <!-- <div class="kt-input-icon kt-input-icon--left col-sm-5">
                                <input type="text" v-model="search" class="form-control" placeholder="Search...">
                                <span @click="searchUser" class="kt-input-icon__icon kt-input-icon__icon--left" style="cursor: pointer;">
                                    <span> <i class="material-icons">search</i> </span>
                                </span>
                            </div> -->
                            <div class="menu" style="float: right;">
                                <sim-dropdown>
                                    <div class="btn">
                                        <i class="material-icons">more_horiz</i>
                                    </div>
                                    <template #menu>
                                        <sim-dropdown-item  v-for="(column, key) in columns" :key="key" @click="column.enabled= !column.enabled" >
                                            <template #icon>
                                                <i class="material-icons" v-if="column.enabled">visibility</i>
                                                <i class="material-icons" v-else>visibility_off</i>
                                            </template>
                                            <template #label>
                                            {{ column.title }}
                                            </template>
                                        </sim-dropdown-item>
                                    </template>
                                </sim-dropdown>
                            </div>
                        </div>
                        <div style="float: left; width: 161px;">
                            <sim-button align="right" :to="{ name: 'brands_administrator' }" :disabled="loading" :loading="loading">
                                <i class="material-icons"> add</i>
                                {{l.app.c_trademarks}}
                            </sim-button>
                        </div>
                    </div>
                    
                    <!-- aqui va la tabla -->
                    <sim-table :columns="columns.filter(el => el.enabled)" :source="pagination.data" bordered @change="orderBy">
                        <template #td:brand_type="{ record }">
                            <span>
                                {{ record.item.brand_type.name }}
                            </span>
                        </template>
                        <template #td:enabled="{ record }">
                            <span v-if="record.item.enabled" class="material-icons icon_table">done</span>
                            <span v-else class="material-icons icon_table">clear</span>
                        </template>
                        <template #td:created_at_format="{ record }">
                            <span>
                                {{ record.item.created_at_format }}
                            </span>
                        </template>
                        <template #td:actions="{ record }">
                            <router-link :to="{ name: 'brands_edit', params: { id_brands: record.item.id } }" style="margin-right: 10px;" class="btn btn-label-warning text-uppercase">
                                <i class="material-icons">edit</i>
                            </router-link> 
                            <label class="btn btn-label-danger text-uppercase" @click="deleteBrands({ id: record.item.id, back: false })">
                                <i class="material-icons"> delete</i>
                            </label>
                        </template>
                    </sim-table>

                    <sim-laravel-pagination align="right" :data="pagination" @change="fetchBrands({with: 'brandType', page: $event, ...query })" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import Loader from '../../components/Loader';

import moment from 'moment';
export default {
    data: () => ({
        columns: [],
        isLoading: false,
    }),
    components: {
        Loader,
    },
    computed: {
        ...mapState({
            pagination: state => state.brands.pagination,
            loading: state => state.brands.loading
        }),
        ...mapGetters({
            l: 'lang'
        })
    },
    methods: {
        ...mapMutations({
            setNavbarTitle: 'SET_NAVBAR_TITLE'
        }),
        ...mapActions({
            fetchBrands: 'brands/fetchBrands',
            deleteBrands: 'brands/delete'
        }),
        searchUser(){
            this.fetchBrands({with: 'brandType', search: this.search, page:1})
        },
        orderBy(data){
            this.isLoading = true;
            if( this.search !== ''){
                this.fetchBrands({with: 'brandType', search: this.search, sort_by: `${data.field},${data.order}`, page:1}).finally(() => {
                    this.isLoading = false;
                });
            } else{
                this.fetchBrands({with: 'brandType', sort_by: `${data.field},${data.order}`, page:1}).finally(() => {
                    this.isLoading = false;
                });
            }
        }
    },
    mounted() {
        this.isLoading = true;
        this.fetchBrands({with: 'brandType', page:1}).finally(() => {
            this.isLoading = false;
        });
    },
    created() {
        this.setNavbarTitle([this.l.app.Brand_List])
        this.columns = [
            { title: this.l.app.Name, key: 'name', enabled: true, sorter:false },
            { title: this.l.app.Description, key: 'description', enabled: true },
            { title: this.l.app.Brand_type, slot: 'brand_type', enabled: true, sorter:false },
            { title: this.l.app.State, key: 'enabled', slot: 'enabled', enabled: true, sorter:false },
            { title: this.l.app.Date, key: 'created_at', slot: 'created_at_format', enabled: true, sorter:false },
            { title: this.l.app.Actions, slot: 'actions', alignment: 'right', enabled: true, style: {width: '242px'} },
        ];
    },
    watch:{
        search (val) {
            if (val.length > 3 ) {
                this.searchUser()
            }
        },
        pagination(val){
            val.data.forEach(value => {
                    value.created_at_format = moment(value.created_at).format('ll');
            });
        }
    }
}
</script>
