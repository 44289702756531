<template>
    <div class="row kt-pt25">
        <div class="col-12 col-sm-12">
            <div class="kt-portlet kt-portlet--height-fluid" style="height: auto;">
                <div class="kt-portlet__body svg_list_trainig">
                    <div style="float: left; width: 100%;">
                        <div style="float: left; width: calc(100% - 236px);">
                            <!-- <div class="kt-input-icon kt-input-icon--left col-sm-5">
                                <input type="text" v-model="search" class="form-control" placeholder="Search...">
                                <span @click="searchUser" class="kt-input-icon__icon kt-input-icon__icon--left" style="cursor: pointer;">
                                    <span> <i class="material-icons">search</i> </span>
                                </span>
                            </div> -->
                            <div class="menu" style="float: right;">
                                <sim-dropdown>
                                    <div class="btn">
                                        <i class="material-icons">more_horiz</i>
                                    </div>
                                    <template #menu>
                                        <sim-dropdown-item  v-for="(column, key) in columns" :key="key" @click="column.enabled= !column.enabled" >
                                            <template #icon>
                                                <i class="material-icons" v-if="column.enabled">visibility</i>
                                                <i class="material-icons" v-else>visibility_off</i>
                                            </template>
                                            <template #label>
                                            {{ column.title }}
                                            </template>
                                        </sim-dropdown-item>
                                    </template>
                                </sim-dropdown>
                            </div>
                        </div>
                        <div style="float: left; width: 236px;">
                            <sim-button align="right" :to="{ name: 'price_ranges_make' }" :disabled="loading" :loading="loading">
                                <i class="material-icons"> add</i>
                                Crear Rangos de precio
                            </sim-button>
                        </div>
                    </div>

                    <!-- aqui va la tabla -->
                    <sim-table :columns="columns.filter(el => el.enabled)" :source="pagination.data" bordered>
                        <template #td:actions="{ record }">
                            <router-link :to="{ name: 'edit_price_ranges_make', params: { id: record.item.id } }" style="margin-right: 10px;" class="btn btn-label-warning text-uppercase">
                                <i class="material-icons">edit</i>
                                editar
                            </router-link> 
                            <label class="btn btn-label-danger text-uppercase" @click="deletePriceRanges({ id: record.item.id, back: false })">
                                <i class="material-icons"> delete</i>
                                Eliminar
                            </label>
                        </template>
                    </sim-table>

                    <sim-laravel-pagination align="right" :data="pagination" @change="fetchPriceRanges({ page: $event, ...query })" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';

export default {
    data: () => ({
        columns: [
            { title: 'Nombre', key: 'name', enabled: true, sorter:true },
            { title: 'Min', key: 'min', enabled: true, sorter:true },
            { title: 'Max', key: 'max', enabled: true, sorter:true },
            { title: 'Acciones', slot: 'actions', alignment: 'right', enabled: true, style: {width: '320px'} },
        ],
    }),
    computed: {
        ...mapState({
            pagination: state => state.PriceRanges.pagination,
            loading: state => state.PriceRanges.loading
        })
    },
    methods: {
        ...mapMutations({
            setNavbarTitle: 'SET_NAVBAR_TITLE'
        }),
        ...mapActions({
            fetchPriceRanges: 'PriceRanges/fetchPriceRanges',
            deletePriceRanges: 'PriceRanges/delete'
        }),
        searchUser(){
            this.fetchPriceRanges({ search: this.search, page:1})
        },
        orderBy(data){
            if( this.search !== ''){
                this.fetchPriceRanges({ search: this.search, sort_by: `${data.field},${data.order}`, page:1});
            } else{
                this.fetchPriceRanges({ sort_by: `${data.field},${data.order}`, page:1});
            }
        }
    },
    mounted() {
        this.fetchPriceRanges({ page:1});
    },
    created() {
        this.setNavbarTitle(['Listado de Rangos de precio'])
    },
    watch:{
        search (val) {
            if (val.length > 3 ) {
                this.searchUser()
            }
        }
    }
}
</script>
