<template>
    <div class="row kt-pt25">
        <div class="col-12 col-sm-12">
            <div class="kt-portlet kt-portlet--height-fluid" style="height: auto;">
                <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item" @click="tabs = 2">
                        <div :class="['nav-link', tabs === 2 && 'active']">
                            <span class="m--visible-desktop-inline-block">
                                {{l.app.GOWN}}
                            </span>
                        </div>
                    </li>
                    <li class="nav-item" @click="tabs = 3">
                        <div :class="['nav-link', tabs === 3 && 'active']">
                            <span class="m--visible-desktop-inline-block">
                                {{l.app.HAUTE_COUTURE}}
                            </span>
                        </div>
                    </li>
                </ul>
                <div v-if="isLoading">
                    <Loader />
                </div>
                <div v-else class="kt-portlet__body svg_list_trainig">
                    <div style="float: left; width: 100%;">
                        <div style="float: left; width: 100%;">
                            <!-- <div class="kt-input-icon kt-input-icon--left col-sm-5">
                                <input type="text" v-model="search" class="form-control" placeholder="Search...">
                                <span @click="searchUser" class="kt-input-icon__icon kt-input-icon__icon--left" style="cursor: pointer;">
                                    <span> <i class="material-icons">search</i> </span>
                                </span>
                            </div> -->
                            <div style="float: left; width: 100%; padding-top: 10px;">
                                <span  :class="['btn-filter', status === '' && 'active']" @click="status = ''">{{l.app.ALL}}</span>
                                <span  :class="['btn-filter', status === 2 && 'active']" @click="status = 2">{{l.app.SUBMITTED}}</span>
                                <span  :class="['btn-filter', status === 3 && 'active']" @click="status = 3">{{l.app.ON_HOLD}}</span>
                                <span  :class="['btn-filter', status === 4 && 'active']" @click="status = 4">{{l.app.APPROVED_ON_SUBMIT}}</span>
                                <span  :class="['btn-filter', status === 5 && 'active']" @click="status = 5">{{l.app.REJECTED_ON_SUBMIT}}</span>
                                <span  :class="['btn-filter', status === 6 && 'active']" @click="status = 6">{{l.app.SENT_FOR_REVIEW}}</span>
                                <span  :class="['btn-filter', status === 7 && 'active']" @click="status = 7">{{l.app.RECEIVED_IN_WAREHOUSE}}</span>
                                <span  :class="['btn-filter', status === 8 && 'active']" @click="status = 8">{{l.app.APPROVED_ON_REVIEW}}</span>
                                <span  :class="['btn-filter', status === 9 && 'active']" @click="status = 9">{{l.app.REJECTED_ON_REVIEW}}</span>
                            </div>
                            <div style="float: left; width: 100%; padding-top: 10px;" class="menu">
                                <sim-dropdown>
                                    <div class="btn">
                                        <i class="material-icons">more_horiz</i>
                                    </div>
                                    <template #menu>
                                        <sim-dropdown-item  v-for="(column, key) in columns.filter(el => el.title !== '')" :key="key" @click="column.enabled= !column.enabled" >
                                            <template #icon>
                                                <i class="material-icons" v-if="column.enabled">visibility</i>
                                                <i class="material-icons" v-else>visibility_off</i>
                                            </template>
                                            <template #label>
                                            {{ column.title }}
                                            </template>
                                        </sim-dropdown-item>
                                    </template>
                                </sim-dropdown>
                            </div>
                        </div>
                    </div>
                    <!-- aqui va la tabla -->
                    <sim-table :columns="columns.filter(el => el.enabled)" :source="pagination.data" @change="orderBy" bordered>
                        <template #td:seleted="{ record }">
                            <sim-checkbox v-model="record.item.selected"/>
                        </template>
                        <template #td:img="{ record }">
                            <span v-if="record.item.garments.length > 0" style="width: 40px; height: 40px; border-radius: 50%; background: #ddd; display: block; margin-left: 10px; overflow: hidden;">
                                <img :src="record.item.garments[0].picture_urls[0].replace(/\.([^\.]*)$/, '_80x112.' + '$1')" alt="" style="width: 100%; height: 100%;">
                            </span>
                            <span v-else class="material-icons" style="width: 40px; height: 40px; border-radius: 50%; background: #ddd; display: block; margin-left: 10px; overflow: hidden; text-align: center; line-height: 40px;">
                                image_not_supported
                            </span>
                        </template>
                        <template #td:number="{ record }">
                            <div class="w-100 info-product"> <b> #{{record.item.number}} </b> </div>
                        </template>
                        <template #td:customer="{ record }">
                            <div class="w-100 img-product relative">
                                <div class="w-100 info-product" style="width: calc(100% - 40px); padding: 0 20px; float: left;"> 
                                    <div class="w-100 info-product"> <b>
                                        {{getKey(record, ['item','customer','email'])}}
                                    </b> </div>
                                    <div class="w-100 info-product"> <b>
                                        {{getKey(record, ['item','customer','first_name'])}}
                                        {{getKey(record, ['item','customer','last_name'])}}
                                    </b> </div>
                                </div>
                            </div>
                        </template>
                        <template #td:type="{ record }">
                            <span v-if="record.item.type === 1">{{l.app.CLOTHING}}</span>
                            <span v-if="record.item.type === 2">{{l.app.GOWN}}</span>
                            <span v-if="record.item.type === 3">{{l.app.HAUTE_COUTURE}}</span>
                            <span v-if="record.item.type === 4">{{l.app.DESIGNER_BAG}}</span>
                        </template>
                        <template #td:status="{ record }">
                            <span v-if="record.item.status === 2">{{l.app.SUBMITTED}}</span>
                            <span v-if="record.item.status === 3">{{l.app.ON_HOLD}}</span>
                            <span v-if="record.item.status === 4">{{l.app.APPROVED_ON_SUBMIT}}</span>
                            <span v-if="record.item.status === 5">{{l.app.REJECTED_ON_SUBMIT}}</span>
                            <span v-if="record.item.status === 6">{{l.app.SENT_FOR_REVIEW}}</span>
                            <span v-if="record.item.status === 7">{{l.app.RECEIVED_IN_WAREHOUSE}}</span>
                            <span v-if="record.item.status === 8">{{l.app.APPROVED_ON_REVIEW}}</span>
                            <span v-if="record.item.status === 9">{{l.app.REJECTED_ON_REVIEW}}</span>
                        </template>
                        <template #td:created_at_format="{ record }">
                            <span>{{record.item.created_at_format}}</span>
                        </template>
                        <template #td:actions="{ record }">
                            <sim-dropdown right fixed>
                                <span class="material-icons">more_vert</span>
                                <template #header>
                                    {{l.app.Actions}}
                                </template>
                                <template #menu v-if="record.item.status !== 1">
                                    <sim-dropdown-item :to="{ name: 'transactions_make', params: { transactions_id: record.item.id } }" class="text-uppercase">
                                        <template #icon>
                                            <i class="material-icons" style="color: #FE9801;">grading</i>
                                        </template>
                                        <template #label>
                                            {{l.app.Detail}}
                                        </template>
                                    </sim-dropdown-item>
                                    <!-- <sim-dropdown-item v-if="record.item.status === 6" @click="transactionsReleasePoints(record.item)" class="text-uppercase" >
                                        <template #icon>
                                            <i class="material-icons">lens</i>
                                        </template>
                                        <template #label>
                                            {{l.app.Release_points}}
                                        </template>
                                    </sim-dropdown-item>
                                    <sim-dropdown-item v-if="record.item.status === 2" @click="transactionsSetOnHoldModal(record.item)" class="text-uppercase" >
                                        <template #icon>
                                            <i class="material-icons">lens</i>
                                        </template>
                                        <template #label>
                                            {{l.app.Set_on_hold}}
                                        </template>
                                    </sim-dropdown-item>
                                    <sim-dropdown-item v-if="record.item.status === 2" @click="transactionsSetApprovedOnSubmit(record.item)" class="text-uppercase" >
                                        <template #icon>
                                            <i class="material-icons">lens</i>
                                        </template>
                                        <template #label>
                                            {{l.app.Pass_photo_evaluation}}
                                        </template>
                                    </sim-dropdown-item>
                                    <sim-dropdown-item v-if="record.item.status === 2" @click="transactionsSetRejectedOnSubmitModal(record.item)" class="text-uppercase" >
                                        <template #icon>
                                            <i class="material-icons">lens</i>
                                        </template>
                                        <template #label>
                                            {{l.app.Reject_photo_evaluation}}
                                        </template>
                                    </sim-dropdown-item>
                                    <sim-dropdown-item v-if="record.item.status === 4" @click="transactionsSetApprovedOnReview(record.item)" class="text-uppercase" >
                                        <template #icon>
                                            <i class="material-icons">lens</i>
                                        </template>
                                        <template #label>
                                            {{l.app.Approve_physical_review}}
                                        </template>
                                    </sim-dropdown-item>
                                    <sim-dropdown-item v-if="record.item.status === 4" @click="transactionsSetRejectedOnReview(record.item)" class="text-uppercase" >
                                        <template #icon>
                                            <i class="material-icons" style="color: #FF6565;">lens</i>
                                        </template>
                                        <template #label>
                                            {{l.app.Refuse_physical_review}}
                                        </template>
                                    </sim-dropdown-item> -->
                                </template>
                                <template #menu v-else>
                                     <sim-dropdown-item :to="{ name: 'transactions_make', params: { transactions_id: record.item.id } }" class="text-uppercase">
                                        <template #icon>
                                            <i class="material-icons" style="color: #FE9801;">grading</i>
                                        </template>
                                        <template #label>
                                            {{l.app.Detail}}
                                        </template>
                                    </sim-dropdown-item>
                                    <!-- <sim-dropdown-item  @click="deleteTransactions({ id: record.item.id, back: false })" class="text-uppercase" >
                                        <template #icon>
                                            <i class="material-icons" style="color: #FF6565;">delete</i>
                                        </template>
                                        <template #label>
                                            {{l.app.Remove}}
                                        </template>
                                    </sim-dropdown-item> -->
                                </template>
                            </sim-dropdown>
                        </template>
                    </sim-table>

                    <sim-laravel-pagination align="right" :data="pagination" @change="fetchTransactions({ ...query, page: $event })" />
                </div>
            </div>
        </div>
        <sim-Modal :show="modal" @close="modal = false">
            <h3 v-if="transaction !== null" slot="header">
                <b class="col-12 title-detail-transaction" style="font-size: 28px; margin-bottom: 20px;">
                    {{l.app.transaction}} #{{transaction.number}}
                </b>
            </h3>
            <div class="row">
                <div class="col-12" v-if="transaction !== null">
                    <div class="w-100 img-product relative" style="width: 80px; float: left;">
                        <img :src="getKey(transaction, ['customer','profile_picture'])" class="img-responsive" style="border-radius: 100%;"/>
                    </div>
                    <div class="w-100 info-product" style="width: calc(100% - 80px); padding: 0 20px; float: left;"> 
                        <div class="w-100 info-product"> <b> {{getKey(transaction, ['customer','email'])}} </b> </div>
                        <div class="w-100 info-product"> <b>
                            {{getKey(transaction, ['customer','first_name'])}}
                            {{getKey(transaction, ['customer','last_name'])}}
                        </b> </div>
                    </div>
                </div>
                <div class="col-12 form-group" v-for="(input, key_input) in  form.filter(el => el.visibility === true)" :key="key_input"> 
                    <label :for="input.atribute">{{input.label}}</label>
                    <sim-input
                        v-if="input.type !== 'select' && input.type !== 'garments' && input.type !== 'attachment'"
                        :id="input.atribute"
                        :type="input.type"
                        :error="error(input.atribute, { attribute: input.atribute })"
                        v-model="senData[input.atribute]"/>
                    <sim-select
                        v-else-if="input.type === 'select'"
                        :source="source[input.source]"
                        :error="error(input.atribute, { attribute: 'Estado' })"
                        :value="senData[input.atribute]"
                        v-model="senData[input.atribute]"
                        @input="activeInput" />

                    <div v-else-if="input.type === 'garments'" class="col-12 row">
                        <div class="col-12 col-sm-6" v-for="(good, key) in transaction.garments" :key="`good_${key}`">
                            <div class="w-100" style="color: #171717">
                                <div class="w-100" style=""> 
                                    <div class="w-100 img-product relative" style="width: 80px; float: left;">
                                        <div class="engoj_find_img">
                                            <img :src="good.pictures[0].path" class="img-responsive" :alt="good.name"/>
                                        </div>
                                    </div>
                                    <div class="w-100 info-product" style="width: calc(100% - 80px); padding: 0 20px; float: left;"> 
                                        <h4 class="capital title-product" style="font-weight: bold;">
                                            {{ good.name }}
                                        </h4>
                                        <span class="col-12" style="font-weight: bold;">{{l.app.size}} {{good.size}}</span>
                                        <span class="col-12" v-if="good.type === 1">{{l.app.CLOTHING}}</span>
                                        <span class="col-12" v-if="good.type === 2">{{l.app.GOWN}}</span>
                                        <span class="col-12" v-if="good.type === 3">{{l.app.HAUTE_COUTURE}}</span>
                                        <span class="col-12" v-if="good.type === 4">{{l.app.DESIGNER_BAG}}</span>
                                        <div class="w-100 content-price-rating" style="font-weight: bold;">
                                            {{good.points_value}} BOTONES
                                        </div>
                                        <sim-button outlined @click=" senData[input.atribute].push(good.id) ">
                                            <i class="material-icons">clear</i>
                                            {{l.app.Cancel}}
                                        </sim-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="input.type === 'attachment'">
                        <sim-img-cropper :aspectRatio="18/25" type="product" :img_url="picture.pictureV" @editImg="($event) =>{ senData[input.atribute] = $event}" @editImgVie="addImgPV($event)" />
                    </div>
                </div>
            </div>
            
            <template slot="footer">
                <sim-button v-if="typeBtn === 1" class="mr-2" @click="transactionsSetOnHold()" :loading="loading">
                    <i class="material-icons">save</i>
                    {{l.app.Save_Changes}}
                </sim-button>
                <sim-button v-if="typeBtn === 2" class="mr-2" @click="transactionsSetRejectedOnSubmit()" :loading="loading">
                    <i class="material-icons">save</i>
                    {{l.app.Save_Changes}}
                </sim-button>
                <sim-button outlined @click="modal = false">
                    <i class="material-icons">clear</i>
                    {{l.app.Cancel}}
                </sim-button>
            </template>

        </sim-Modal>
    </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import Loader from '../../components/Loader';


import moment from 'moment';
import { 
    transactionsFromRejected,
    transactionsReleasePoints,
    transactionsSetOnHold,
    transactionsSetOnHoldtow,
    transactionsSetApprovedOnSubmit,
    transactionsSetRejectedOnSubmit,
    transactionsSetApprovedOnReview,
    transactionsSetRejectedOnReview
} from '@/api'

export default {
    components: {
        Loader,
    },
    data: () => ({
        tabs: 2,
        status: '',
        columns: [],
        limit: 10,
        query: {
            limit: 10,
            type: 2,
            status: '',
            administrators: true,
            with:'customer,garments.pictures',
            sort_by:'created_at,desc',
            page:1
        },
        search:'',
        modal:false,
        form:[],
        senData: {},
        picture: {label: 'Primera Imagen', pictureV: '', file: null},
        transaction: null,
        typeBtn: 1,
        isLoading: false,
    }),
    computed: {
        ...mapState({
            pagination: state => state.transactions.pagination,
            loading: state => state.transactions.loading,
        }),
        ...mapGetters({
            hasError: 'hasError',
            error: 'error',
            l: 'lang'
        }),
    },
    methods: {
        ...mapMutations({
            setNavbarTitle: 'SET_NAVBAR_TITLE'
        }),
        ...mapActions({
            fetchTransactions: 'transactions/fetchTransactions',
            deleteTransactions: 'transactions/delete'
        }),
        getKey(obj, klist) {
            while (obj && klist.length) {
                obj = obj[klist.shift()];
            }
            return obj;
        },
        searchUser(){
            this.fetchTransactions({with:'customer,garments.pictures',search: this.search, page:1})
        },
        orderBy(data){
            this.isLoading = true;
            if( this.search !== ''){
                this.fetchTransactions({with:'customer,garments.pictures', search: this.search, sort_by: `${data.field},${data.order}`, page:1}).finally(() => {
                    this.isLoading = false;
                });
            } else{
                this.fetchTransactions({with:'customer,garments.pictures', sort_by: `${data.field},${data.order}`, page:1}).finally(() => {
                    this.isLoading = false;
                });
            }
        },
        addImgPV(img) {
            this.picture.pictureV = img;
        },
        transactionsFromRejected(){
            this.isLoading = true;
            transactionsFromRejected().then(() => {
                this.fetchTransactions({with:'customer,garments.pictures', page:1}).finally(() => {
                    this.isLoading = false;
                });
            })
        },
        transactionsReleasePoints(data){
            this.isLoading = true;
            transactionsReleasePoints(data.id).then(() => {
                this.fetchTransactions({with:'customer,garments.pictures', page:1}).finally(() => {
                    this.isLoading = false;
                });
            })
        },
        transactionsSetOnHoldModal(data){
            //aquie se tiene que hacer un modal para pedir esta data
            this.form = [
                {label: this.l.app.Reason, atribute: 'reason', type: 'text', visibility: true},
                {label: this.l.app.Description, atribute: 'description', type: 'text', visibility: true},
                {label: this.l.app.garments, atribute: 'rejected_garments', type: 'garments', visibility: true},
                {label: this.l.app.Images, atribute: 'attachment', type: 'attachment', visibility: true},
            ];
            this.senData = {
                reason: '',
                description: '',
                attachment: null,
                rejected_garments:[]
            };
            this.transaction = data;
            this.modal= true;
            this.picture= {label: 'Primera Imagen', pictureV: '', file: null};
            this.typeBtn=1;
        },
        transactionsSetOnHold(){
            this.isLoading = true;
            if(this.senData.attachment !== null){
                const transactionsSetOnHoldData = new FormData();
                      transactionsSetOnHoldData.append('_method', 'PATCH');
                      transactionsSetOnHoldData.append('reason', this.senData.reason);
                      transactionsSetOnHoldData.append('description', this.senData.description);
                      transactionsSetOnHoldData.append('attachment', this.senData.attachment);
                this.senData.rejected_garments.forEach(element => {
                    transactionsSetOnHoldData.append('rejected_garments[]', element);
                });

                transactionsSetOnHoldtow(this.transaction.id, transactionsSetOnHoldData).then(() => {
                    this.fetchTransaction(this.transaction.id);
                    this.modal = false;
                }).finally(() => {
                    this.isLoading = false;
                });
            }else{
                transactionsSetOnHold(this.transaction.id, this.senData).then(() => {
                    this.fetchTransaction(this.transaction.id);
                    this.modal = false;
                }).finally(() => {
                    this.isLoading = false;
                });
            }
        },
        transactionsSetApprovedOnSubmit(data){
            this.isLoading = true;
            transactionsSetApprovedOnSubmit(data.id).then(() => {
                this.fetchTransactions({with:'customer,garments.pictures', page:1}).finally(() => {
                    this.isLoading = false;
                });
            })
        },
        transactionsSetRejectedOnSubmitModal(data){
            // Libera las prendas y las coloca en su estado inicial
            // Se puede crear una nueva transaccion desde una rechazada en este estado
            this.form = [
                {label: this.l.app.Reason, atribute: 'reason', type: 'text', visibility: true},
                {label: this.l.app.Description, atribute: 'description', type: 'text', visibility: true},
                {label: this.l.app.Images, atribute: 'attachment', type: 'attachment', visibility: true},
            ];
            this.senData = {
                reason: '',
                description: '',
                rejected_garments:[]
            };
            this.transaction = data;
            this.modal= true;
            this.picture= {label: 'Primera Imagen', pictureV: '', file: null};
            this.typeBtn=2;
        },
        transactionsSetRejectedOnSubmit(){
            if(this.senData.rejected_garments.length > 0){
                const transactionsSetRejectedOnSubmitData = new FormData();
                Object.entries(this.senData).forEach((entry)=>{
                    transactionsSetRejectedOnSubmitData.append(entry[0], entry[1]);
                })
                transactionsSetRejectedOnSubmit(this.transaction.id, transactionsSetRejectedOnSubmitData).then(() => {
                    this.$message.success({
                        message: this.l.messages.responses.Transaction_status,
                        position: `top-right`
                    })
                    this.fetchTransactions({with:'customer,garments.pictures', page:1});
                    this.modal = false;
                })
            } else {
                transactionsSetRejectedOnSubmit(this.transaction.id, this.senData).then(() => {
                    this.$message.success({
                        message: this.l.messages.responses.Transaction_status,
                        position: `top-right`
                    })
                    this.fetchTransactions({with:'customer,garments.pictures', page:1});
                    this.modal = false;
                })
            }
        },
        transactionsSetApprovedOnReview(data){
            transactionsSetApprovedOnReview(data.id).then(() => {
                this.$message.success({
                    message: this.l.messages.responses.Transaction_status,
                    position: `top-right`
                })
                this.fetchTransactions({with:'customer,garments.pictures', page:1});
            })
        },
        transactionsSetRejectedOnReview(data){
            transactionsSetRejectedOnReview(data.id).then(() => {
                this.$message.success({
                    message: this.l.messages.responses.Transaction_status,
                    position: `top-right`
                })
                this.fetchTransactions({with:'customer,garments.pictures', page:1});
            })
        },

    },
    mounted() {
        this.isLoading = true;
        this.fetchTransactions(this.query).finally(() => {
            this.isLoading = false;
        });
    },
    created() {
        this.setNavbarTitle([this.l.app.List_of_Transactions]);
        this.columns= [
            { title: '', slot: 'seleted', alignment: 'right', enabled: false, style: {width: '29px'} },
            { title: '', slot: 'img', alignment: 'right', enabled: true, style: {width: '40px'} },
            { title: this.l.app.columnsTransaction.c_t_1 , slot: 'number', key: 'number', alignment: 'right', enabled: true, sorter:true },
            { title: this.l.app.columnsTransaction.c_t_2 , slot: 'customer', key: 'customer_id', enabled: true, sorter:true },
            { title: this.l.app.columnsTransaction.c_t_3 , slot: 'type', key: 'type', alignment: 'right', enabled: true, sorter:true },
            { title: this.l.app.columnsTransaction.c_t_4 , slot: 'status', key: 'status', alignment: 'right', enabled: true, sorter:true},
            { title: this.l.app.columnsTransaction.c_t_5 , key: 'estimated_customer_points', alignment: 'right', enabled: true, sorter:true},
            { title: this.l.app.columnsTransaction.c_t_6 , key: 'obtained_customer_points', alignment: 'right', enabled: true, sorter:true},
            { title: this.l.app.columnsTransaction.c_t_7 , slot: 'created_at_format', key: 'created_at', sorter:true, enabled: true },
            { title: this.l.app.columnsTransaction.c_t_8 , slot: 'actions', alignment: 'right', enabled: true, style: {width: '42px'} },
        ];
    },
    watch:{
        search (val) {
            if (val.length > 3 ) {
                this.searchUser()
            }
        },
        pagination(val){
            val.data.forEach(value => {
                    value.created_at_format = moment(value.created_at).format('ll');
            });
        },
        tabs(val){
            this.query.type= val;
            this.fetchTransactions({...this.query, page:1});
        },
        status(val){
            this.query.status= val;
            this.fetchTransactions(this.query);
        }
    }
}
</script>
<style lang="scss">
.w-100{
    float: left;
    width: 100%;
}
</style>