<template>
<div>
        <div class="flex flex-wrap mb-2">
            <label @click="$router.back()" class="btn text-uppercase">
                <i class="material-icons">keyboard_backspace</i>
                {{l.app.To_return}}
            </label>
        </div>
        <div class="row kt-pt25">
            <div class="col-12 col-sm-12">
                <div class="kt-portlet kt-portlet--height-fluid" style="height: auto;">
                    <div class="kt-portlet__body svg_list_trainig">
                        <div v-if="order !== null" class="row">
                            <div class="col-12 col-sm-4 row" style="padding: 0px;">
                                <div class="col-12" style="padding: 0px;">
                                    <div class="col-12 order-header">
                                        <div class="row">
                                            <b class="col-12 title-detail-order" style="font-size: 14px; margin-bottom: 20px;">
                                                <b style="padding: 5px 12px 9px 0px; float: left;">{{l.app.Order}} {{order.number}}</b>
                                                <span style="background: #E7E7E7; color: #171717; display: table; padding: 5px 9px; border-radius: 16px; margin-right: 10px; font-size: 12px; float: right;" v-if="order.status === 1">{{l.app.PENDING}}</span>
                                                <span style="background: #626AC9; color: #ffff; display: table; padding: 5px 9px; border-radius: 16px; margin-right: 10px; font-size: 12px; float: right;" v-if="order.status === 2">{{l.app.BEING_PREPARED}}</span>
                                                <span style="background: #00BCD4; color: #ffff; display: table; padding: 5px 9px; border-radius: 16px; margin-right: 10px; font-size: 12px; float: right;" v-if="order.status === 3">{{l.app.SENT}}</span>
                                                <span style="background: #FF6565; color: #ffff; display: table; padding: 5px 9px; border-radius: 16px; margin-right: 10px; font-size: 12px; float: right;" v-if="order.status === 4">{{l.app.CANCELED}}</span>
                                                <span style="background: rgb(29 239 37); color: #ffff; display: table; padding: 5px 9px; border-radius: 16px; margin-right: 10px; font-size: 12px; float: right;" v-if="order.status === 5">{{l.app.COMPLETED}}</span>
                                            </b>
                                            
                                            <span class="col-12">
                                                <b style="padding: 5px 12px 9px 0px; float: left;">{{l.app.service}}:</b>
                                                <span style="background: #E7E7E7; color: #171717; display: table; padding: 5px 9px; border-radius: 16px; margin-right: 10px; font-size: 12px; float: left;" v-if="order.type === 1">{{l.app.CLOTHING}}</span>
                                                <span style="background: #E7E7E7; color: #171717; display: table; padding: 5px 9px; border-radius: 16px; margin-right: 10px; font-size: 12px; float: left;" v-if="order.type === 2">{{l.app.GOWN}}</span>
                                                <span style="background: #E7E7E7; color: #171717; display: table; padding: 5px 9px; border-radius: 16px; margin-right: 10px; font-size: 12px; float: left;" v-if="order.type === 3">{{l.app.HAUTE_COUTURE}}</span>
                                                <span style="background: #E7E7E7; color: #171717; display: table; padding: 5px 9px; border-radius: 16px; margin-right: 10px; font-size: 12px; float: left;" v-if="order.type === 4">{{l.app.DESIGNER_BAG}}</span>
                                            </span>
                                            <span class="col-12">
                                               <b>{{l.app.Date_the_purchase_was_made}}:</b> {{order.created_at_format}}
                                            </span>
                                        </div>
                                        <div class="cont_nun res" style="font-size: 18px;">
                                            <b>{{l.app.Rode_in_app_points}}:</b>
                                            {{order.points_deferred}}
                                            <span class="material-icons icon_poin" style="font-size: 14px;">lens</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12" style="padding: 30px 9px;">
                                    <b style="width: 100%; padding: 5px 12px 9px 0px; float: left;">{{l.app.Customer}}:</b>
                                    <div class="col-12" style="border: 1px solid rgb(231, 231, 231); color: #171717; display: table; padding: 5px 9px; border-radius: 16px; margin-right: 10px; font-size: 12px; float: left;">
                                        <div class="w-100 img-product relative" style="width: 80px; float: left;">
                                            <img :src="order.customer.profile_picture" class="img-responsive" style="border-radius: 100%;"/>
                                        </div>
                                        <div class="w-100 info-product" style="width: calc(100% - 80px); padding: 0 20px; float: left;"> 
                                            <div class="w-100 info-product text-overflow-ellipsis"> <b> {{order.customer.email}} </b> </div>
                                            <div class="w-100 info-product"> <b> {{order.customer.first_name}} {{order.customer.last_name}} </b> </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="order.address !== null" class="col-12">
                                    <div class="title-detail-order" style="font-size: 14px; font-weight: bold; margin-bottom: 20px;">
                                        {{l.app.Shipping_address}}
                                    </div>
                                    <div class="col-12" style="border: 1px solid rgb(231, 231, 231); color: #171717; display: table; padding: 5px 9px; border-radius: 16px; margin-right: 10px; font-size: 12px; float: left;">
                                        <div class="kt-notification__item-title">
                                            {{l.app.Address}}: <b>{{order.address.line}} </b>
                                        </div>
                                        <div class="kt-notification__item-title">
                                            {{l.app.Description}}: {{order.address.line_2}}
                                        </div>
                                        <div class="kt-notification__item-title">
                                            {{l.app.Telephone}}: {{order.address.phone}}
                                        </div>
                                        <div class="kt-notification__item-title">
                                            {{l.app.City}}: {{order.address.city}}
                                        </div>
                                        <div class="kt-notification__item-title">
                                            {{l.app.State}}: {{order.address.state}}
                                        </div>
                                        <div class="kt-notification__item-title">
                                            {{l.app.Postal_Code}}: {{order.address.zip_code}}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-12 col-sm-4">

                                <div class="w_100" style="padding: 20px;">
                                    <div class="title-detail-transaction" style="font-size: 14px;">
                                        {{l.app.Tracking}}
                                    </div>
                                    <div class="list-traking" v-for="(track, key) in order.status_tracking" :key="`status_tracking_${key}`">
                                        <div class="icon" style="padding: 0">
                                            <span v-if="track.status === 1" class="material-icons">check</span>
                                            <span v-if="track.status === 2" class="material-icons">construction</span>
                                            <span v-if="track.status === 3" class="material-icons">local_shipping</span>
                                            <span v-if="track.status === 4" class="material-icons">not_interested</span>
                                            <span v-if="track.status === 5" class="material-icons">done_all</span>
                                        </div>
                                        <div v-if="(key + 1) < order.status_tracking.length" class="line-traking"></div>
                                        <div class="content_text">
                                            <span class="name_traking" v-if="track.status === 1">{{l.app.PENDING}}</span>
                                            <span class="name_traking" v-if="track.status === 2">{{l.app.BEING_PREPARED}}</span>
                                            <span class="name_traking" v-if="track.status === 3">{{l.app.SENT}}</span>
                                            <span class="name_traking" v-if="track.status === 4">{{l.app.CANCELED}}</span>
                                            <span class="name_traking" v-if="track.status === 5">{{l.app.COMPLETED}}</span>
                                            <samp class="name_traking">{{track.timestamp_at_format}}</samp>
                                        </div>
                                    </div>
                                    <div v-if="order.shipping_ref_id !== null" style="float: left; width: 50%; padding: 5px;">
                                        <a style="line-height: 27px; background: rgb(0, 188, 212); color: rgb(255, 255, 255); display: table; padding: 5px 9px; border-radius: 16px; margin-right: 10px; font-size: 12px; float: right; width: 100%; text-align: center;" :href="order.shipping_ref_id" target="_blank" rel="noopener noreferrer">
                                            <span class="material-icons" style="line-height: 27px; position: absolute; margin-left: -32px; color: #fff;">local_shipping</span>
                                            XPS
                                        </a>
                                    </div>
                                    <div v-if="order.usps_tracking_code !== null" style="float: left; width: 50%; padding: 5px;">
                                        <a style="line-height: 27px; background: rgb(0, 188, 212); color: rgb(255, 255, 255); display: table; padding: 5px 9px; border-radius: 16px; margin-right: 10px; font-size: 12px; float: right; width: 100%; text-align: center;" :href="order.usps_tracking_code" target="_blank" rel="noopener noreferrer">
                                            <span class="material-icons" style="line-height: 27px; position: absolute; margin-left: -32px; color: #fff;">local_shipping</span>
                                            USPS
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-4">
                                <div class="col-12 title-detail-order" style="font-size: 14px; font-weight: bold; margin-bottom: 20px;">
                                    {{l.app.Items}}
                                </div>
                                <template v-if="order.garments.length > 0  ">
                            
                                <div class="col-12" style="margin-bottom: 20px;border: 1px solid rgb(231, 231, 231); color: #171717; display: table; padding: 5px 9px; border-radius: 16px; margin-right: 10px; font-size: 12px; float: left;" v-for="(good, key) in order.garments" :key="`good_${key}`">
                                    <div class="w-100" style="color: #171717">
                                        <div class="w-100"  @click="oppent($event, good )" style=""> 
                                            <div class="w-100 img-product relative" style="width: 80px; float: left;">
                                                <div class="engoj_find_img">
                                                    <img style="border-radius: 16px;" :src="good.pictures[0].path" class="img-responsive" :alt="good.name"/>
                                                </div>
                                            </div>
                                            <div class="w-100 info-product row" style="width: calc(100% - 80px); padding: 0 20px; float: left;"> 
                                                <b class="col-12 capital title-product" style="font-weight: bold;">
                                                    {{ good.name }}
                                                </b>
                                                <span class="col-12" style="font-weight: bold;">{{l.app.size}}: {{l.app.sizeArray['s_'+good.size]}}</span>
                                                <div class="col-12 content-price-rating" style="font-weight: bold;">
                                                   <b>{{l.app.Price}}:</b> {{good.points_value}} <span class="material-icons icon_poin" style="font-size: 14px;">lens</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </template>

                            </div>
                            <div class="col-12 row">
                                <sim-dropdown-item v-if="order.status === 1"  @click="handler_setbeingprepared(order.id)" class="col text-uppercase" >
                                    <template #icon>
                                        <i class="material-icons" style="color: #626AC9;">assignment_turned_in</i>
                                    </template>
                                    <template #label>
                                        {{l.app.Prepare}}
                                    </template>
                                </sim-dropdown-item>
                                <sim-dropdown-item  v-if="order.status === 2" @click="handler_setsentModal()" class="col text-uppercase" >
                                    <template #icon>
                                        <i class="material-icons" style="color: #00BCD4;">send</i>
                                    </template>
                                    <template #label>
                                        {{l.app.Send}}
                                    </template>
                                </sim-dropdown-item>
                                <sim-dropdown-item v-if="order.status !== 5 && order.status !== 4" @click="handler_setcanceled(order.id)" class="col text-uppercase" >
                                    <template #icon>
                                        <i class="material-icons" style="color: #FF6565;">cancel_schedule_send</i>
                                    </template>
                                    <template #label>
                                        {{l.app.Cancel}}
                                    </template>
                                </sim-dropdown-item>
                                <sim-dropdown-item v-if="order.status === 3" @click="download_shipping()" class="col text-uppercase" >
                                    <template #icon>
                                        <i class="material-icons" style="color: rgb(29 239 37);">picture_as_pdf</i>
                                    </template>
                                    <template #label>
                                       {{l.app.Download_shipping}}
                                    </template>
                                </sim-dropdown-item>
                                <sim-dropdown-item v-if="order.status === 3" @click="handler_setcompleted(order.id)" class="col text-uppercase" >
                                    <template #icon>
                                        <i class="material-icons" style="color: rgb(29 239 37);">check_circle_outline</i>
                                    </template>
                                    <template #label>
                                        {{l.app.Finish}}
                                    </template>
                                </sim-dropdown-item>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <sim-Modal :show="modal" @close="modal = false">
            <h3 v-if="order !== null" slot="header">
                <b class="col-12 title-detail-order" style="font-size: 14px; margin-bottom: 20px;">
                    {{l.app.Order}} #{{order.number}}
                </b>
            </h3>

            <div class="form-group">
                <label for="password">{{l.app.shipping_date}}</label>
                <v-datepicker v-model="shipment_date" value-type="YYYY-MM-DD" format="YYYY-MM-DD"></v-datepicker>
            </div>

            <template slot="footer">
                <sim-button @click="handler_setsent()" class="mr-2" :loading="loading" :disabled="setSentLoading">
                    <i class="material-icons">save</i>
                    {{l.app.Save_Changes}}
                </sim-button>
                <sim-button outlined @click="modal = false">
                    <i class="material-icons">clear</i>
                    {{l.app.Cancel}}
                </sim-button>
            </template>

        </sim-Modal>
</div>

</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import { setbeingprepared, setsent, setcanceled, setcompleted} from '@/api';
import vDatepicker from 'vue2-datepicker';
import moment from 'moment';
import { URL } from '@/constants';
import { getCookie } from '@/helpers/storage';
const token = getCookie(process.env.VUE_APP_USER_TOKEN_NAME)
export default {
    data: () => ({
        search:'',
        modal: false,
        shipment_date: '',
		setSentLoading: false
    }),
    components: {
        vDatepicker
    },
    computed: {
        ...mapState({
            order: state => state.order.order,
            loading: state => state.order.loading,
        }),
        ...mapGetters({
            l: 'lang'
        })
    },
    methods: {
        ...mapMutations({
            setNavbarTitle: 'SET_NAVBAR_TITLE'
        }),
        ...mapActions({
            fetchOrder: 'order/fetchOrder',
            deleteorders: 'order/delete'
        }),
        handler_setbeingprepared(id){
            if (confirm(this.l.app.confirm_order_1)) {
                setbeingprepared(id).then(()=>{
                    this.fetchOrder(id);
                    this.$message.success({
                        message: this.l.messages.responses.Order_status,
                        position: `top-right`
                    })
                })
            }
        },
        handler_setsentModal(){
           this.modal= true;
        },
        handler_setsent(){
			this.setSentLoading = true;
            setsent(this.order.id, {shipment_date: this.shipment_date})
            .then(( )=>{
				this.setSentLoading = false;
				this.modal = false;
                this.$message.success({
                    message: this.l.messages.responses.Order_status,
                    position: `top-right`
                })
                this.fetchOrder(this.order.id);
            })
			.catch(() => {
				this.modal = false;
				this.setSentLoading = false;
			})
        },
        handler_setcanceled(id){
            if (confirm(this.l.app.confirm_order_3)) {
                setcanceled(id).then(()=>{
                    this.fetchOrder(this.order.id);
                    this.$message.success({
                        message: this.l.messages.responses.Order_status,
                        position: `top-right`
                    })

                })
            }
        },
        download_shipping(){
            window.open(`${URL}api/orders/${this.order.id}/shipping-label/pdf?token=${token}`, '_blank');
        },
        handler_setcompleted(id){
            if (confirm(this.l.app.confirm_order_4)) {
                setcompleted(id).then(()=>{
                    this.fetchOrder(this.order.id);
                    this.$message.success({
                        message: this.l.messages.responses.Order_status,
                        position: `top-right`
                    })
                    
                })
            }
        }
    },
    created() {
        this.setNavbarTitle([this.l.app.List_of_orders, this.l.app.Detail])
        const id = this.$route.params.order_id;
        this.fetchOrder(id);
    },
    watch:{
        order (val) {
            val.created_at_format = moment(val.created_at).format('LLL');
            
            val.status_tracking.forEach(element => {
                element.timestamp_at_format = moment(element.timestamp).format('LT');
            });
        }
    }
}
</script>

<style lang="scss">

.list-traking{
    width: 100%;
    float: left;
    min-height: 100px;
    position: relative;

    .icon{
        float: left;
        width: 36px;
        height: 36px;
        padding: 3px 8px;
        border: 2px solid #CCCCCC;
        border-radius: 100%;
        margin-right: 11px;
        .material-icons{
            float: left;
            width: 100%;
            text-align: center;
            font-size: 23px;
            line-height: 31px;
        }
    }

    .line-traking{
        height: calc(100% - 36px);
        position: absolute;
        border: 2px solid #ccc;
        left: 16px;
        top: 36px;
    }

    .content_text{
        width: calc(100% - 48px);
        float: left;

        .name_traking{
            width: calc(100% - 48px);
            float: left;
            font-weight: bold;
            font-size: 18px;
        }

        .date{
            width: calc(100% - 48px);
            float: left;
        }
    }

    &.active{
        .icon{
            border: 2px solid #626AC9;
            svg{
                fill: #626AC9;
            }
        }
        .line-traking{
            border: 2px solid #626AC9;
        }
    }
}
.mx-datepicker-popup{
    z-index: 200000001 !important;
}
.c-6{
    float: left;
    width: 50%;
}
</style>