<template>
    <div>
        <div class="flex flex-wrap mb-2">
            <label @click="$router.back()" class="btn text-uppercase">
                <i class="material-icons">keyboard_backspace</i>
                {{l.app.To_return}}
            </label>
        </div>
        <div class="row kt-pt25">
            <div class="col-12 col-sm-12">
                <div class="kt-portlet kt-portlet--height-fluid" style="height: auto;">
                    <div v-if="priceRangesData !== null" class="kt-portlet__body svg_list_trainig">

                        <div class="col-6" style="padding: 30px 9px;">
                            <b style="width: 100%; padding: 5px 12px 9px 0px; float: left;">{{this.l.app.Customer}}:</b>
                            <div class="col-12" style="border: 1px solid rgb(231, 231, 231); color: #171717; display: table; padding: 5px 9px; border-radius: 16px; margin-right: 10px; font-size: 12px; float: left;">
                                <div class="w-100 img-product relative" style="width: 80px; float: left;">
                                    <img :src="priceRangesData.profile_picture" class="img-responsive" style="border-radius: 100%;"/>
                                </div>
                                <div class="w-100 info-product" style="width: calc(100% - 80px); padding: 0 20px; float: left;"> 
                                    <div class="w-100 info-product text-overflow-ellipsis"> <b> {{priceRangesData.email}} </b> </div>
                                    <div class="w-100 info-product"> <b> {{priceRangesData.first_name}} {{priceRangesData.last_name}} </b> </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-6" style="padding: 30px 9px;">
                            <b style="width: 100%; padding: 5px 12px 9px 0px; float: left;">{{this.l.app.Plan}}:</b>
                            <div class="col-12" style="border: 1px solid rgb(231, 231, 231); color: #171717; display: table; padding: 5px 9px; border-radius: 16px; margin-right: 10px; font-size: 12px; float: left;">
                                    <div class="w-100 info-product text-overflow-ellipsis"> <b> {{priceRangesData.subscription.plan}} </b> </div>
                                    <div class="w-100 info-product"> <b> {{priceRangesData.subscription.current_period_start}} / {{priceRangesData.subscription.current_period_end}} </b> </div>
                            </div>
                        </div>

                        <div class="col-6" style="padding: 30px 9px;">
                            <b style="width: 100%; padding: 5px 12px 9px 0px; float: left;">{{l.app.Last_bill}}:</b>
                            <div class="col-12" style="border: 1px solid rgb(231, 231, 231); color: #171717; display: table; padding: 5px 9px; border-radius: 16px; margin-right: 10px; font-size: 12px; float: left;">
                                    <div class="w-100 info-product text-overflow-ellipsis"> Total: <b> ${{priceRangesData.subscription.upcoming_invoice.total /100}} </b> </div>
                                    <div class="w-100 info-product">{{l.app.Next_payment_attempt_readable}} <b> {{priceRangesData.subscription.upcoming_invoice.next_payment_attempt_readable}}</b> </div>
                                    <div class="w-100 info-product" style="margin-bottom: 20px;">{{l.app.Next_payment_attempt}} <b> {{priceRangesData.subscription.upcoming_invoice.next_payment_attempt}}</b> </div>
                                    <a style="display: table; margin: 10px auto;" :href="priceRangesData.subscription.latest_invoice.hosted_url" class="sim-button is-primary" target="_blank" rel="noopener noreferrer"> {{l.app.More_details}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
//import moment from 'moment';
import { stateSubscription, fetchCustomer } from '@/api';

export default {
    data: () => ({
        priceRangesData: null
    }),
    computed: {
        ...mapGetters({
            hasError: 'hasError',
            error: 'error',
            l: 'lang'
        }),
        ...mapState({
            lang: state => state.lang,
            errorsS: state => state.errors,
            PriceRanges: state => state.PriceRanges.pagination.data,
            loading: state => state.PriceRanges.loading,
            customers: state => state.customers.pagination,
            categories: state => state.categories.pagination,
            brands: state => state.brands.pagination,
            priceRange: state => state.PriceRanges.PriceRanges,
        })
    },
    methods: {
        ...mapMutations({
            setNavbarTitle: 'SET_NAVBAR_TITLE'
        }),
    },
    async created() {
        try {
            this.setNavbarTitle([this.l.app.Subscription_List, this.l.app.Detail])
            const id = this.$route.params.customer_id;
            //this.setNavbarTitle(['Listado de Suscripción'])
            const res_Customer = await fetchCustomer(id);
            const res_Subscription = await stateSubscription(id);
            this.priceRangesData = {
                ...res_Customer.data,
                subscription: res_Subscription.data
            }
        } catch (error) {
            this.priceRangesData = error;
        }

    
    }
}
</script>

<style lang="scss">
.w-100{
    float: left;
    width: 100%;
}
</style>